<div class="container-fluid visit-store" *ngIf="config.title !== 'Aiema' && config.title !== 'AvonSeals' && config.title !== 'Babys Pride' && config.title !== 'Schift Cosmetics' && config.title !== 'Cle' && config.title !== 'LinenRaj' && config.title !== 'SpecialWires' && config.title !== 'Vistas' && config.title !=='Vimpro'&& config.title !=='LushNGreen' && config.title !=='Taazah' && config.title !=='KatbookStore' && config.title !=='Accel' && config.title !=='SriSenbaga' && config.title!=='HOB' && config.title!=='PaulCaroline' && config.title!=='Srimaware'">
    <ul>
        <li>
            <h3 *ngIf="config.title !== 'BombayHardware'">Visit our latest products</h3>
            <h3 style="color: #1e1e73;" *ngIf="config.title == 'BombayHardware'">Visit our latest products</h3>
        </li>
        <li>
            <a class="btn btn-secondary " *ngIf="config.title !== 'BombayHardware'" (click)="getLatestProducts()">Know
                More &nbsp; <i class="fa-angle-right fa"></i></a>
            <a style="background-color:#1e1e73!important ;color: white!important;border-color:#1e1e73!important"
                class="btn btn-secondary " *ngIf="config.title == 'BombayHardware'" (click)="getLatestProducts()">Know
                More</a>
        </li>
    </ul>
</div>
<div class="container-fluid vimpro-sub" *ngIf="config.title==='Vimpro'">
    <div class="foot-disc">
        <!-- <h3 >Logo goes here</h3> -->
        <img src="assets/images/vimpro/vimpro_logo.png" class="footer_logo" [routerLink]="['/']">
    </div>
    <div class="col-md-5">
        <div class="inq-news-fld-st ">
            <form [formGroup]="subscribeForm" novalidate class="responive_acc">
                <input type="text" placeholder="Enter your mail.." class="email" formControlName="email"
                    [class.is-invalid]="f.email.invalid && validateForm" required>
                <button class="vimpro-subbtn" (click)="subscribe()">
                    <p style="
                        color: white;">SUBSCRIBE</p>
                </button>
                <div class="invalid-feedback" *ngIf="f.email.errors">
                    <div *ngIf="f.email.errors.required">
                        Email Id is required.
                    </div>
                    <div *ngIf="f.email.errors.pattern">
                        Enter correct Email Id.
                    </div>
                    <div *ngIf="f.email.errors.maxlength">
                        Email can't be more than 50 characters long.
                    </div>
                </div>

            </form>
            <!-- <app-alerts></app-alerts> -->
        </div>
    </div>
    <!-- <div class="foot-text">
        <input type="text" placeholder="Enter your mail.." class="email">
        <button class="vimpro-subbtn" >
            <p style="
            color: white;">SUBSCRIBE</p>
        </button>
    </div> -->
    <div class="foot-icon">
        <div class="s-icons">
            <ul class="social-icons">
                <li class="vimpro-social">
                    <a href="#" style="color:white !important">
                        <i class="fa fa-facebook"></i>
                    </a>
                </li>
                <li class="vimpro-social">
                    <a href="#" style="color:white !important">
                        <i class="fa fa-twitter"></i>
                    </a>
                </li>
                <li class="vimpro-social">
                    <a href="#" style="color:white !important">
                        <i class="fa fa-linkedin"></i>
                    </a>
                </li>
                <li class="vimpro-social">
                    <a href="#" style="color:white !important">
                        <i class="fa fa-google-plus" aria-hidden="true"></i>
                    </a>
                </li>
                <li class="vimpro-social">
                    <a href="#" style="color:white !important">
                        <i class="fa fa-pinterest" aria-hidden="true"></i>
                    </a>
                </li>

            </ul>
        </div>
    </div>
</div>
<!-- <hr *ngIf="config.title ==='Vimpro'" style="margin-left:15%;width:70%;margin-top:0px;margin-bottom:0px;"> -->
<div class="container-fluid visit-store" *ngIf="config.title === 'Aiema'"
    style="background-image: linear-gradient(to right top, #051937, #004d7a, #008793, #37b4b0, #33e4de);font-family: 'proxima-regular','sans-serif'!important;">
    <ul style="margin-bottom:0px !important">
        <li>
            <h3>Explore AIEMA</h3>
        </li>
        <li>
            <a class="btn btn-secondary" (click)="getLatestProducts()">Know More &nbsp; <i
                    class="fa-angle-right fa"></i></a>
        </li>
    </ul>
</div>
<!-- <div class="container-fluid visit-store" *ngIf="config.title === 'Cle'" style="background-image: linear-gradient(to right top, #883a01, #883a01, #41413f, #41413f, #41413f);font-family: 'proxima-regular','sans-serif'!important;">
    <ul style="margin-bottom:0px !important">
        <li>
            <h3>Explore CLE</h3>
        </li>
        <li>
            <a class="btn btn-secondary" (click)="getLatestProducts()" >Know More &nbsp; <i
                    class="fa-angle-right fa"></i></a>
        </li>
    </ul>
</div> -->
<footer class="container-fluid footer" *ngIf="config.title !== 'Aiema' && config.title !== 'Cle' && config.title !== 'LinenRaj' && config.title !== 'Vimpro' && config.title !== 'BombayHardware' && config.title !== 'AvonSeals' && config.title !== 'Babys Pride' && config.title !== 'Schift Cosmetics' && config.title !== 'SpecialWires' && config.title !== 'Vistas' && config.title !== 'Beaubelle' && config.title!='Accel' && config.title!='LushNGreen' && config.title !=='Taazah' && config.title !=='SriSenbaga' && config.title !=='KatbookStore' && config.title !== 'HOB' && config.title!=='PaulCaroline' && config.title!=='Srimaware' ">
    <div class="container">
        <div class="footer-links footer-row-one">
            <div class="footer-col">
                <h4 class="sm-bold" style="color: #212529;">ONLINE SHOPPING</h4>
                <ul>
                    <li *ngFor="let online of onLineShopping;" (click)="navigateToProduct(online.link)"><a
                            style="color: #375673 !important;font-weight:450" class="custom-point">{{online.name}}</a>
                    </li>
                </ul>
            </div>
            <div class="footer-col">
                <h4 class="sm-bold" style="color: #212529;">QUICK LINKS</h4>
                <ul>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/">Home</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="about-us">About Us</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="contact-us">Contact Us</a></li>
                    <!-- <li (click)="getBrochure()"><a >Brochure</a></li> -->
                </ul>
            </div>
            <div class="footer-col">
                <h4 class="sm-bold" style="color: #212529;">USEFUL LINKS</h4>
                <ul>
                    <!-- <li><a style="color: #375673 !important;font-weight:450" routerLink="about-us">Contact Us</a></li> -->
                    <!-- <li><a style="color: #375673 !important;font-weight:450" routerLink="/home">FAQ</a></li> -->
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/terms-and-condition">Terms &
                            Conditions</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/privacy-policy">Privacy
                            Policy</a></li>
                    <li><a style="color: #375673 !important;font-weight:450"
                            routerLink="/cancellation-policy">Cancellation Policy</a></li>
                    <li><a style="color: #375673 !important;font-weight:450"
                            routerLink="/shipping-and-delivery">Shipping & Delivery</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/return-policy">Return &
                            Exchanges</a></li>
                </ul>
            </div>
            <div class="footer-col contact-section address" *ngIf="config.title!=='Vimpro'">
                <h4 class="sm-bold" style="color: #212529;">CONTACT US</h4>
                <p style="color: #375673 !important;"><i class="fa-map-marker fa"></i>
                    Green Cotton Company<br>
                    No. 8, BM – 441,<br>
                    1st Block HRBR Layout,<br>
                    Bangalore – 560043,<br>
                    Karnataka.
                </p>
                <p><i class="fa-phone  fa"></i>
                    <a style="color: #375673 !important;font-weight:450">+91 8884500900</a>
                </p>
                <p><i class="fa-globe  fa"></i>
                    <a style="color: #375673 !important;font-weight:450" href="enquiry@earthyworthy.in"
                        target="_blank">enquiry@earthyworthy.in</a>
                </p>
            </div>
            <div class="footer-col contact-section address" *ngIf="config.title==='Vimpro'">
                <h4 class="sm-bold" style="color: #212529;">CONTACT US</h4>
                <p style="color: #375673 !important;"><i style="color: #1f1a1a !important;"
                        class="fa-map-marker fa"></i>
                    VIMPROTECH,<br>
                    A7, Thattanchavady Industrial Estate,<br>
                    Pondicherry - 605009<br>
                    Tamilnadu<br>

                </p>
                <p><i style="color: #1f1a1a !important;" class="fa-phone  fa"></i>
                    <a style="color: #375673 !important;font-weight:450">
                        +91 92446 56656</a>
                </p>
                <p><i style="color: #1f1a1a !important;" class="fa-globe  fa"></i>
                    <a style="color: #375673 !important;font-weight:450" href="enquiry@earthyworthy.in"
                        target="_blank">vimprotech@gmail.com.</a>
                </p>
            </div>
        </div>
    </div>
</footer>



<footer class="container-fluid footer" *ngIf="config.title == 'Accel'" style="overflow: hidden;">
    <!-- <hr> -->
    <div class="container">
        <div class="footer-links footer-row-one accel_footer">
            <div class="footer-col">
                <h4 class="sm-bold" style="color: #212529;">POPULAR COURSES</h4>
                <ul class="li_data">
                    <li style="cursor: pointer;"><a style="font-weight:450"
                            (click)="sendProductDetails1('Diploma In Computer Hardware And Networking')"
                            class="li_data li_change"> Networking</a></li>
                    <li style="cursor: pointer;"><a style="font-weight:450"
                            (click)="sendProductDetails1('Certificate Program In Linux Server Administration')"
                            class="li_data li_change"> Linux Server Administration</a></li>
                    <li style="cursor: pointer;"><a style="font-weight:450"
                            (click)="sendProductDetails1('Pg Program In Cyber Security')" class="li_data li_change">
                            Cyber Security</a></li>
                    <li style="cursor: pointer;"><a style="font-weight:450"
                            (click)="sendProductDetails1('Pg Program In Cloud Engineering')" class="li_data li_change">
                            Cloud Engineering</a></li>
                    <!-- <li   *ngFor="let online of onLineShopping;" (click)="navigateToProduct(online.link)"><a  style="font-weight:450"
                            class="custom-point li_data li_change" >{{online.name}}</a></li> -->
                </ul>
            </div>
            <div class="footer-col">
                <h4 class="sm-bold" style="color: #212529;">INFORMATION</h4>
                <ul class="li_data">
                    <li><a style="font-weight:450" routerLink="/" class="li_data li_change"> Home</a></li>
                    <li><a style="font-weight:450" routerLink="about-us" class="li_data li_change">About Us</a></li>
                    <li><a style="font-weight:450" routerLink="contact-us" class="li_data li_change"> Contact Us</a>
                    </li>
                    <li><a style="font-weight:450" routerLink="/terms-and-condition" class="li_data li_change">Terms &
                            Conditions</a></li>
                    <li><a style="font-weight:450" routerLink="/privacy-policy" class="li_data li_change">Privacy
                            Policy</a></li>
                    <!-- <li><a style="font-weight:450" routerLink="/cancellation-policy"  class="li_data li_change">Cancellation Policy</a></li> -->
                    <!-- <li><a style="font-weight:450" routerLink="/shipping-and-delivery"  class="li_data li_change">Shipping & Delivery</a></li>
                    <li><a style="font-weight:450" routerLink="/return-policy"  class="li_data li_change">Return & Exchanges</a></li> -->
                    <!-- <li (click)="getBrochure()"><a >Brochure</a></li> -->
                </ul>
            </div>
            <div class="footer-col">
                <h4 class="sm-bold" style="color: #212529;">ALL CATEGORIES</h4>
                <ul class="li_data">
                    <!-- <li><a style="color: #375673 !important;font-weight:450" routerLink="about-us">Contact Us</a></li> -->
                    <!-- <li><a style="color: #375673 !important;font-weight:450" routerLink="/home">FAQ</a></li> -->
                    <li><a style="font-weight:450" (click)="nav_accel_cate1()" class="li_data li_change"
                            style="cursor: pointer;">Long Term Courses</a></li>
                    <li><a style="font-weight:450" (click)="nav_accel_cate2()" class="li_data li_change"
                            style="cursor: pointer;">Short Term Courses</a></li>
                </ul>
            </div>
            <div class="footer-col contact-section address">
                <h4 class="sm-bold" style="color: #212529;text-align: left;">CONTACT US</h4>
                <ul class="li_data">
                    <li style="color: #375673 !important;white-space: nowrap;">
                        <i class="fa-map-marker fa"></i>
                        #175, SFI Complex, Nungambakkam, Chennai-600034
                    </li>
                    <li style="white-space: nowrap;">
                        <i class="fa-phone  fa"></i>
                        <a style="color: #375673 !important;font-weight:450">+91-44-2822 2263/+91 73050 82265 </a>
                    </li>
                    <li>
                        <i class="fa-globe  fa"></i>
                        <a style="color: #375673 !important;font-weight:450" href="info@accelacademy.in"
                            target="_blank">info@accelacademy.in</a>
                    </li>

                </ul>


            </div>

        </div>
    </div>
</footer>
<footer class="container-fluid footer" *ngIf="config.title === 'Vimpro'">
    <div class="container">
        <div class="footer-links footer-row-one">
            <div class="footer-col">
                <h4 class="sm-bold" style="color: #212529;">ONLINE SHOPPING</h4>
                <ul>
                    <li *ngFor="let online of onLineShopping_vimpro;" (click)="navigateToList(online.link)"><a
                            class="custom-point1">{{online.name}}</a></li>
                </ul>
            </div>
            <div class="footer-col">
                <h4 class="sm-bold" style="color: #212529;">QUICK LINKS</h4>
                <ul>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/">Home</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="about-us">About Us</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="contact-us">Contact Us</a></li>
                    <!-- <li (click)="getBrochure()"><a >Brochure</a></li> -->
                </ul>
            </div>
            <div class="footer-col">
                <h4 class="sm-bold" style="color: #212529;">USEFUL LINKS</h4>
                <ul>
                    <!-- <li><a style="color: #375673 !important;font-weight:450" routerLink="about-us">Contact Us</a></li> -->
                    <!-- <li><a style="color: #375673 !important;font-weight:450" routerLink="/home">FAQ</a></li> -->
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/terms-and-condition">Terms &
                            Conditions</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/privacy-policy">Privacy
                            Policy</a></li>
                    <li><a style="color: #375673 !important;font-weight:450"
                            routerLink="/cancellation-policy">Cancellation Policy</a></li>
                    <li><a style="color: #375673 !important;font-weight:450"
                            routerLink="/shipping-and-delivery">Shipping & Delivery</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/return-policy">Return &
                            Exchanges</a></li>
                </ul>
            </div>
            <div class="footer-col contact-section address" *ngIf="config.title==='Vimpro'">
                <h4 class="sm-bold" style="color: #212529;">CONTACT US</h4>
                <p style="color: #375673 !important;"><i style="color: #1f1a1a !important;"
                        class="fa-map-marker fa"></i>
                    VIMPROTECH,<br>
                    A7, Thattanchavady Industrial Estate,<br>
                    Pondicherry - 605009<br>
                    Tamilnadu<br>

                </p>
                <p><i style="color: #1f1a1a !important;" class="fa-phone  fa"></i>
                    <a style="color: #375673 !important;font-weight:450">
                        +91 92446 56656</a>
                </p>
                <p><i style="color: #1f1a1a !important;" class="fa-globe  fa"></i>
                    <a style="color: #375673 !important;font-weight:450" href="enquiry@earthyworthy.in"
                        target="_blank">vimprotech@gmail.com.</a>
                </p>
            </div>
        </div>
    </div>
</footer>
<footer class="container-fluid footer" *ngIf="config.title === 'Beaubelle'">
    <div class="container">
        <div class="footer-links footer-row-one">
            <div class="footer-col">
                <h4 class="sm-bold" style="color: #212529;">ONLINE SHOPPING</h4>
                <ul>
                    <li *ngFor="let online of onLineShopping;" (click)="navigateToCategory(online.name)"><a
                            style="color: #375673 !important;font-weight:450" class="custom-point">{{online.name}}</a>
                    </li>
                </ul>
            </div>
            <div class="footer-col">
                <h4 class="sm-bold" style="color: #212529;">QUICK LINKS</h4>
                <ul>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/">Home</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="about-us">About Us</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="contact-us">Contact Us</a></li>
                    <!-- <li (click)="getBrochure()"><a >Brochure</a></li> -->
                </ul>
            </div>
            <div class="footer-col">
                <h4 class="sm-bold" style="color: #212529;">USEFUL LINKS</h4>
                <ul>
                    <!-- <li><a style="color: #375673 !important;font-weight:450" routerLink="about-us">Contact Us</a></li> -->
                    <!-- <li><a style="color: #375673 !important;font-weight:450" routerLink="/home">FAQ</a></li> -->
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/terms-and-condition">Terms &
                            Conditions</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/privacy-policy">Privacy
                            Policy</a></li>
                    <li><a style="color: #375673 !important;font-weight:450"
                            routerLink="/cancellation-policy">Cancellation Policy</a></li>
                    <li><a style="color: #375673 !important;font-weight:450"
                            routerLink="/shipping-and-delivery">Shipping & Delivery</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/return-policy">Return &
                            Exchanges</a></li>
                </ul>
            </div>
            <div class="footer-col contact-section address">
                <h4 class="sm-bold" style="color: #212529;">CONTACT US</h4>
                <p style="color: #375673 !important;"><i class="fa-map-marker fa"></i>
                    Beaubelle Products & Care Private Limited<br>
                    53/76, Venkatachalam Street,<br>
                    Royapuram,<br>
                    Chennai 600013,<br>
                    Tamil Nadu.
                </p>
                <p><i class="fa-phone  fa"></i>
                    <a style="color: #375673 !important;font-weight:450">+91 9444667560 </a>
                </p>
                <p><i class="fa-globe  fa"></i>
                    <a style="color: #375673 !important;font-weight:450" href="amirtha@beaubelle.in"
                        target="_blank">amirtha@beaubelle.in</a>
                </p>
            </div>
        </div>
    </div>
</footer>
<footer class="container-fluid footer" *ngIf="config.title === 'Aiema'">
    <div class="container" style="font-family: 'proxima-regular','sans-serif'!important;">
        <div class="footer-links footer-row-one">
            <div class="footer-col">
                <h4 class="sm-bold" style="color: #212529; font-weight: 650;">ONLINE ENQUIRY</h4>
                <ul>
                    <li *ngFor="let online of onLineShopping;" (click)="navigateToProduct(online.link)"><a
                            style="color: #375673 !important;font-weight:450" class="custom-point">{{online.name}}</a>
                    </li>
                </ul>
            </div>
            <div class="footer-col">
                <h4 class="sm-bold" style="color: #212529;  font-weight: 650;">QUICK LINKS</h4>
                <ul>
                    <li><a style="color: #375673 !important;font-weight:450" (click)='scrollFunction()'
                            routerLink="/">Home</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" (click)='scrollFunction()'
                            routerLink="about-us">About Us</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" (click)='scrollFunction()'
                            routerLink="contact-us">Contact Us</a></li>
                    <!-- <li (click)="getBrochure()"><a >Brochure</a></li> -->
                </ul>
            </div>
            <div class="footer-col">
                <h4 class="sm-bold" style="color: #212529;  font-weight: 650;">USEFUL LINKS</h4>
                <ul>
                    <li><a style="color: #375673 !important;font-weight:450" (click)='scrollFunction()'
                            routerLink="/terms-and-condition">Terms & Conditions</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" (click)='scrollFunction()'
                            routerLink="/privacy-policy">Privacy Policy</a></li>
                </ul>
            </div>
            <div class="footer-col contact-section address">
                <h4 class="sm-bold" style="color: #212529;  font-weight: 650;">CONTACT US</h4>
                <p *ngIf="config.title === 'Aiema' || config.title === 'Cle' || config.title === 'LinenRaj'"
                    style="color: #375673 !important;"><i class="fa-map-marker fa"></i>
                    Ambattur Industrial Estate Manufacturers’ Association,<br>
                    ATC Road,<br>
                    Ambattur Industrial Estate,<br>
                    Chennai – 600058,<br>
                    Tamilnadu.
                </p>
                <p><i class="fa-phone  fa"></i>
                    <a style="color: #375673 !important;font-weight:450">+91 8939628603</a>
                </p>
                <p><i class="fa-envelope  fa"></i>
                    <a style="color: #375673 !important;font-weight:450"
                        href="mailto: mail@aiema.net">mail@aiema.net</a>
                </p>
            </div>
        </div>
    </div>
</footer>

<footer class="container-fluid footer" *ngIf="config.title === 'Cle'" style="background-color: #f6f6f6;">
    <div class="container" style="font-family: 'proxima-regular','sans-serif'!important;">
        <div class="footer-links footer-row-one">
            <div class="footer-col">
                <h4 class="sm-bold" style="color: #212529;font-weight: 650;">Online Enquiry</h4>
                <ul>
                    <li *ngFor="let online of onLineShopping;" (click)="navigateToProduct(online.link)"><a
                            style="color: #375673 !important;font-weight:450" class="custom-point">{{online.name}}</a>
                    </li>
                </ul>
            </div>
            <div class="footer-col">
                <h4 class="sm-bold" style="color: #212529;  font-weight: 650;">Quick Links</h4>
                <ul>
                    <li><a style="color: #375673 !important;font-weight:450" (click)='scrollFunction()'
                            routerLink="/">Home</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" (click)='scrollFunction()'
                            routerLink="about-us">About Us</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" (click)='scrollFunction()'
                            routerLink="contact-us">Contact Us</a></li>
                    <!-- <li (click)="getBrochure()"><a >Brochure</a></li> -->
                </ul>
            </div>

            <div class="footer-col">
                <h4 class="sm-bold" style="color: #212529;  font-weight: 650;">Useful Links</h4>
                <ul>
                    <li><a style="color: #375673 !important;font-weight:450" (click)='scrollFunction()'
                            routerLink="/terms-and-condition">Terms & Conditions</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" (click)='scrollFunction()'
                            routerLink="/privacy-policy">Privacy Policy</a></li>
                </ul>
            </div>
            <div class="footer-col contact-section address">
                <h4 class="sm-bold" style="color: #212529;  font-weight: 650;">Contact Us</h4>
                <p *ngIf="config.title === 'Cle' || config.title === 'LinenRaj'" style="color: #375673 !important;"><i
                        class="fa-map-marker fa"></i>
                    Council for Leather Exports,<br>
                    No.1, Sivaganga Road, <br>
                    Nungambakkam,
                    Chennai – 600034, India<br>

                </p>
                <p><i class="fa-phone  fa"></i>
                    <a style="color: #375673 !important;font-weight:450">044 48684380-84 </a>
                </p>
                <p><i class="fa-envelope  fa"></i>
                    <a style="color: #375673 !important;font-weight:450"
                        href="mailto: cle@cleindia.com">cle@cleindia.com</a>
                </p>
            </div>
        </div>
    </div>
</footer>



<footer class="container-fluid footer" *ngIf="config.title === 'LinenRaj' " style="border-top: 2px solid #a77737;">
    <div class="container linencontainer"
        style="font-family: 'Lato', sans-serif;font-size:small ; margin-top: 3%;margin-left:5%;">
        <div class="footer-links footer-row-one">
            <h1 style="display: none;">Premium Linen Shirts</h1>
            <div class="footer-col" style="position: relative;left:4%;">
                <img [src]="logoImage" alt="Logo Image" class="custom-pointer aiema-logo desktop-only" height="60"
                    alt="Logo" style="margin-left:30%;box-shadow: none;margin-left: -88px;
                width: 227%;
                height: 40%;" [routerLink]="['/']">
                <img [src]="logoImage" alt="Logo Image" class="custom-pointer linen-logo-footer mobile-only" height="90"
                    alt="Logo" style="" [routerLink]="['/']">
                <div class="dis-jj">
                    <p class="p_ln">Discover a consciously elevated & </p>
                    <p class="p_ln"> responsibly crafted fashion brand, with</p>
                    <p class="p_ln"> an Indian heart.</p>
                    <br>
                </div>
                <!-- <p class="p_ln" style="font-size: 11px !important;">Free Shipping  |  2-Month Free Return  |  COD Available</p> -->

            </div>
            <div class="footer-col linen-footer-shop" style="position: relative;">
                <h4 class="sm-bold" class="f_h">SHOP</h4>
                <ul>
                    <li class="f_l" style="cursor: pointer;" (click)="getProducts()">Shirts</li>
                    <!-- <li class="f_l">Polos</li>
                    <li class="f_l">Crew Necks</li>
                    <li class="f_l">Bottom Wear</li> -->
                    <!-- <li *ngFor="let online of onLineShopping;" (click)="navigateToProduct(online.link)"><a style="color: #375673 !important;font-weight:450"
                            class="custom-point">{{online.name}}</a></li> -->
                </ul>
            </div>
            <div class="footer-col">
                <h4 class="sm-bold" class="f_h">INFORMATION</h4>
                <ul>

                    <li><a class="f_l" (click)='scrollFunction()' routerLink="about-us">About Us</a></li>
                    <li><a class="f_l" (click)='scrollFunction()' routerLink="contact-us">Contact Us</a></li>
                    <li><a class="f_l" (click)='scrollFunction()' routerLink="/terms-and-condition">Terms &
                            Conditions</a></li>
                    <li><a class="f_l" (click)='scrollFunction()' routerLink="/privacy-policy">Privacy Policy</a></li>
                    <!-- <li ><a   class="f_l"(click)='scrollFunction()' routerLink="/">We Are Hiring</a></li> -->
                    <!-- <li (click)="getBrochure()"><a >Brochure</a></li> -->
                </ul>
            </div>

            <div class="footer-col manage_align">
                <h4 class="sm-bold" class="f_h">MANAGE</h4>
                <ul>
                    <li><a class="f_l" style="cursor: pointer;" (click)="showOrder()">Track Order</a></li>
                    <li><a class="f_l" (click)='scrollFunction()' routerLink="FAQs">Return/Exchange</a></li>
                    <!-- <li ><a  class="f_l" (click)='scrollFunction()' routerLink="/terms-and-condition">Free Shipping & Returns</a></li> -->
                    <!-- <li ><a   class="f_l" (click)='scrollFunction()' routerLink="/privacy-policy">Referral Policy</a></li> -->


                </ul>
            </div>
            <div class="footer-col contact-section address connect_align">
                <h4 class="sm-bold" class="f_h">CONNECT WITH US</h4>
                <li class="f_l">
                    <i class="fa-map-marker fa"></i>
                    Smrithi corporation,<br>

                    2/17, Ganapathipalayam,<br>

                    Udumalpet - 642122,<br>


                </li>
                <li class="f_l"><i class="fa-phone  fa" style="font-family: FontAwesome !important;"></i>(+91)
                    9842229639</li>
                <li class="f_l"> <i class="fa-envelope  fa"></i>care@linenraj.com</li>
                <!-- <li class="f_l"><i class="fas fa-clock"></i>Mon-Fri | 10:00 AM - 06:30 PM (IST)</li> -->
                <li class="f_l"><span class="facebook-icon" style="width: fit-content;    height: 40px;">
                        <i class="fab fa-facebook-f" style="margin: 0px 4px 0px 4px;"></i>
                    </span>
                    <span class="facebook-icon" style="margin-left: 5px; height: 40px;">
                        <i class="fab fa-instagram" style="margin-right: 0px;"></i>
                    </span>
                    <span class="facebook-icon" style="margin-left: 5px; height: 40px;">
                        <i class="fab fa-linkedin-in" style="margin-right: 0px;"></i>

                    </span>

                </li>


                <!-- <p style="color: #375673 !important;"><i class="fa-map-marker fa"></i>
                    Council for Leather Exports,<br>
                    No.1, Sivaganga Road, <br>
                    Nungambakkam,
                    Chennai – 600034, India<br>
                    
                </p>
                <p><i class="fa-phone  fa"></i>
                    <a style="color: #375673 !important;font-weight:450">044 48684380-84 </a>
                </p>
                <p><i class="fa-envelope  fa"></i>
                    <a style="color: #375673 !important;font-weight:450" href="mailto: cle@cleindia.com">cle@cleindia.com</a>
                </p> -->


            </div>
        </div>
    </div>
    <div>
        <p class="" style="color: #636e7b;text-align: center; font-size: 12px;">© 2015-2023 LinenRaja. All rights
            reserved.</p>
    </div>
</footer>














<footer class="container-fluid footer" *ngIf="config.title === 'BombayHardware'" style="background-color: #1e1e73;">
    <div class="container" style="font-family: 'proxima-regular','sans-serif'!important;">
        <div class="footer-links footer-row-one">
            <div class="footer-col">

                <ul>
                    <li><img style="width: 100%;"
                            src="https://ecbee-labes-images1.s3.ap-south-1.amazonaws.com/BombayHardware/StoreGallery/BombayHardware-Logo-reverse.png">
                    </li>
                </ul>
            </div>
            <div class="footer-col">
                <h4 class="sm-bold" style="color:#cfb77b;">ONLINE ENQUIRY</h4>
                <ul>
                    <li *ngFor="let online of onLineShopping;" (click)="navigateToProduct(online.link)"><a
                            style="color: white !important;font-weight:450" class="custom-point">{{online.name}}</a>
                    </li>
                </ul>
            </div>
            <div class="footer-col">
                <h4 class="sm-bold" style="color: #cfb77b;">QUICK LINKS</h4>
                <ul>
                    <li><a style="color: white !important;font-weight:450" (click)='scrollFunction()'
                            routerLink="/">Home</a></li>
                    <li><a style="color: white !important;font-weight:450" (click)='scrollFunction()'
                            routerLink="about-us">About Us</a></li>
                    <li><a style="color: white !important;font-weight:450" (click)='scrollFunction()'
                            routerLink="contact-us">Contact Us</a></li>
                    <!-- <li (click)="getBrochure()"><a >Brochure</a></li> -->
                </ul>
            </div>
            <div class="footer-col">
                <h4 class="sm-bold" style="color:#cfb77b ;">USEFUL LINKS</h4>
                <ul>
                    <li><a style="color: white !important;font-weight:450" (click)='scrollFunction()'
                            routerLink="/terms-and-condition">Terms & Conditions</a></li>
                    <li><a style="color: white !important;font-weight:450" (click)='scrollFunction()'
                            routerLink="/privacy-policy">Privacy Policy</a></li>
                </ul>
            </div>
            <div class="footer-col contact-section address">
                <h4 class="sm-bold" style="color: #cfb77b;">CONTACT US</h4>
                <p style="color: white !important;"><i style="color: #cfb77b;" class="fa-map-marker fa"></i>
                    Bombay Hardware Private Limited<br>
                    No. 32, Sembudoss Street, 1st Floor, Parrys,<br>
                    Corner Estate, George Town, <br>
                    Chennai - 600001,<br>
                    Tamil Nadu, India
                </p>
                <p><i style="color: #cfb77b;" class="fa-phone  fa"></i>
                    <a style="color:white !important;font-weight:450">08048976462</a>
                </p>
                <p><i style="color: #cfb77b;" class="fa-envelope  fa"> <a
                            style="color: white !important;font-weight:450"> info@bombayhardware.com</a></i>
                    <a style="color: white !important;font-weight:450" href=""></a>
                </p>
            </div>
        </div>
    </div>
</footer>

<footer class="container-fluid footer" *ngIf="config.title !== 'AvonSeals'&& config.title !== 'Schift Cosmetics' && config.title !== 'Babys Pride'  && config.title !== 'SpecialWires' && config.title !== 'Vistas' && config.title !== 'BombayHardware' && config.title !== 'Aiema' && config.title !== 'Cle' &&  config.title !== 'LinenRaj' && config.title!=='Vimpro' && config.title!=='Beaubelle' && config.title!=='Accel' && config.title!=='LushNGreen' && config.title !=='Taazah' && config.title !=='SriSenbaga' && config.title !=='KatbookStore' && config.title !== 'HOB' && config.title!=='PaulCaroline' && config.title!=='Srimaware' " >
    <div class="container">
        <div class="footer-links footer-row-one">

            <div class="footer-col">
            </div>
            <div class="footer-col">
                <h4 class="sm-bold" style="color: #212529;">USEFUL LINKS</h4>
                <ul>

                    <li><a style="color: #375673 !important;font-weight:450" routerLink="contact-us"
                            *ngIf="config.title != 'ShubhCards' && config.title != 'Deera' && config.title != 'AvonSeals' && config.title !== 'SpecialWires' && config.title != 'BestGifts' && config.title != 'Carience'">Contact
                            Us</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/FAQs"
                            *ngIf="config.title != 'Deera' && config.title != 'AvonSeals' && config.title != 'BestGifts' && config.title !== 'SpecialWires' && config.title != 'Carience'">FAQ</a>
                    </li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/terms-and-condition"
                            *ngIf="config.title != 'AvonSeals' && config.title !== 'SpecialWires'">T&amp;C</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/privacy-policy"
                            *ngIf="config.title != 'AvonSeals' && config.title !== 'SpecialWires'">Privacy Policy</a>
                    </li>
                    <li *ngIf="config.title != 'Carience'"><a style="color: #375673 !important;font-weight:450"
                            routerLink="/cancellation-policy"
                            *ngIf="config.title != 'AvonSeals' && config.title !== 'SpecialWires'">Cancellation
                            Policy</a></li>
                    <li *ngIf="config.title != 'Carience'"><a style="color: #375673 !important;font-weight:450"
                            routerLink="/shipping-and-delivery"
                            *ngIf="config.title != 'AvonSeals' && config.title !== 'SpecialWires'">Shipping &
                            Delivery</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/return-policy"
                            *ngIf="config.title == 'ShubhCards' || config.title == 'Deera' ||config.title == 'BestGifts'">Return
                            & Exchanges</a></li>
                </ul>
            </div>

            <div class="footer-col contact-section address" *ngIf="config.title == 'LuckMe'">
                <h4 class="sm-bold" style="color: #212529;">CONTACT US</h4>
                <p style="color: #375673 !important;"><i class="fa-map-marker fa"></i>
                    B S M Complex, Revolution Av,<br>
                    Victoria, Mahe
                    <br>

                </p>
                <p><i class="fa-phone  fa"></i>
                    <a style="color: #375673 !important;font-weight:450">+248 251 3732</a>
                </p>
                <p><i class="fa-globe  fa"></i>
                    <a style="color: #375673 !important;font-weight:450" href="sales@supersoma.sc"
                        target="_blank">sales@supersoma.sc</a>
                </p>
            </div>
            <div class="footer-col contact-section address" *ngIf="config.title == 'ShubhCards'">
                <h4 class="sm-bold" style="color: #212529;">CONTACT US</h4>
                <p style="color: #375673 !important;"><i class="fa-map-marker fa"></i>
                    New #16,Subramaniam Street, Abhiramapuram,Chennai,<br>
                    Tamil Nadu 600018
                    <br>

                </p>
                <p><i class="fa-phone  fa"></i>
                    <a style="color: #375673 !important;font-weight:450">+91-44-42327190</a>
                </p>
                <p><i class="fa fa-mobile"></i>
                    <a style="color: #375673 !important;font-weight:450">+91-98400 49389</a>
                </p>
                <p><i class="fa-globe  fa"></i>
                    <a style="color: #375673 !important;font-weight:450" target="_blank">shubhcards@gmail.com</a>
                </p>
            </div>
            <div class="footer-col contact-section address" *ngIf="config.title == 'Deera'">
                <h4 class="sm-bold" style="color: #212529;">CONTACT US</h4>
                <p style="color: #375673 !important;"><i class="fa-map-marker fa"></i>
                    Panduranga Enterprises - Import & Trading - NO.84/1, 4th Floor, 3rd Cross, New Timber Yard Layout,
                    Bengaluru<br>
                    Karanataka - 560026
                    <br>

                </p>
                <!-- <p><i class="fa-phone  fa"></i>
                    <a style="color: #375673 !important;font-weight:450">+91-44-42327190</a>
                </p> -->
                <p><i class="fa fa-mobile"></i>
                    <a style="color: #375673 !important;font-weight:450">8310065761</a>
                </p>
                <p><i class="fa-globe  fa"></i>
                    <a style="color: #375673 !important;font-weight:450" target="_blank">deera2021@gmail.com</a>
                </p>
            </div>
            <!-- <div class="footer-col contact-section address" *ngIf = "config.title == 'AvonSeals'" >
                <h4 class="sm-bold" style="color: #212529;">CONTACT US</h4>
                <p style="color: #375673 !important;"><i class="fa-map-marker fa"></i>
                    235, 9th Street, Sidco Industrial Estate, Ambattur<br>
                    Chennai - 600 098
                    <br>
                    
                </p> -->
            <!-- <p><i class="fa-phone  fa"></i>
                    <a style="color: #375673 !important;font-weight:450">+91-44-42327190</a>
                </p> -->
            <!-- <p><i class="fa fa-mobile"></i>
                    <a style="color: #375673 !important;font-weight:450">+91 9790955409 / 044 42017070</a>
                </p>
                <p><i class="fa-globe  fa"></i>
                    <a style="color: #375673 !important;font-weight:450"  target="_blank">avonseals.ecbee.net</a>
                </p>
            </div> -->

        </div>
    </div>
</footer>
<footer class="container-fluid footer schiftfoot221" *ngIf="config.title === 'Schift Cosmetics'">
    <!-- <hr style="border: 1px solid #020a12;"> -->
    <div class="container">
        <div class="footer-links footer-row-one schift_foortot">
            <div class="schift_foor2">
                <div class="footer-col schift_footreq1">

                    <ul>
                        <!-- <li *ngIf="config.title === 'Schift Cosmetics'"> <img src="../../assets/images/schift/logo.png" alt="Logo Image" class="custom-pointer avon-foot-img" alt="Logo"
                            [routerLink]="['/']"> </li> -->
                        <figure class="elementor-image-box-img"><a tabindex="-1"><img decoding="async" width="70"
                                    height="68" src="../../../../../assets/images/schift/footer-icon-1.png"
                                    class="attachment-full size-full wp-image-44" alt=""></a></figure>
                        <div style="width: 100%;">
                            <h4 class="schift-text-key3">Have any questions?</h4>
                        </div>
                        <div style="justify-content: center; display: flex;">
                            <button class="schift_home_button-2" (click)="questions()">Get In Touch</button>
                        </div>
                    </ul>
                </div>
            </div>
            <div class="schift_foor2">
                <div class="footer-col schift_footreq1">

                    <ul>
                        <figure class="elementor-image-box-img"><a tabindex="-1"><img decoding="async" width="70"
                                    height="68" src="../../../../../assets/images/schift/footer-icon-2.png"
                                    class="attachment-full size-full wp-image-44" alt=""></a></figure>
                        <h4 class="schift-text-key3">Track Your Order</h4>
                        <div class="text-align-cent">
                            <!-- <p class="scift-text4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus.</p> -->
                            <button class="schift_home_button-2" (click)="showOrder()">Orders</button>
                        </div>
                    </ul>
                </div>
            </div>
            <div class="schift_foor2">
                <div class="footer-col schift_footreq2">

                    <ul>
                        <figure class="elementor-image-box-img"><a tabindex="-1"><img decoding="async" width="70"
                                    height="68" src="../../../../../assets/images/schift/footer-icon-3.png"
                                    class="attachment-full size-full wp-image-44" alt=""></a></figure>
                        <h4 class="schift-text-key3">Return & Refund</h4>
                        <div class="text-align-cent">
                            <!-- <p class="scift-text4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus.</p> -->
                            <button routerLink="return-policy" class="schift_home_button-2">Read More</button>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>
<footer class="container-fluid footer" style="background-color: #80808024;" *ngIf="config.title === 'AvonSeals'">
    <hr style="border: 1px solid #020a12;">
    <div class="container">
        <div class="footer-links footer-row-one" style="margin-top: -34px;">

            <div class="footer-col">

                <ul>
                    <li *ngIf="config.title === 'AvonSeals'"> <img src="../../assets/images/avonseals/Avon Seals.png"
                            alt="Logo Image" class="custom-pointer avon-foot-img1" alt="Logo" [routerLink]="['/']">
                    </li>

                    <!-- <li><p style="color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;" *ngIf = "config.title == 'AvonSeals'">Viverra suspendisse potenti nullam ac. Sed lectus vestibulum mattis ullamcorper.</p></li><br> -->

                    <!-- <div class="buttonIn" style="display:flex;width:300px">
                        <input type="text" id="enter" placeholder="Enter Your Email">
                        <button id="subscribe" style="color: white; background-color: #ff1f1f;height:55px;width:160px !important">Subscribe</button>
                    </div><br>
                    <li><p style="color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;" *ngIf = "config.title == 'AvonSeals'">We do not spam. We send offers instead.</p></li> -->

                </ul>
            </div>
            <div class="footer-col avon-footer-cont" *ngIf="config.title == 'AvonSeals' ">
                <ul style="list-style-type:disc">
                    <li style="list-style-type: disc;color: #020a12; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;cursor: pointer;"
                        (click)="windowscroll()" routerLink="/">Home</li>
                    <li style="list-style-type: disc;color: #020a12; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;cursor: pointer;"
                        routerLink="about-us">About Us</li>
                    <!-- <li style="list-style-type: disc;color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;">Blog</li> -->
                    <!-- <li style="list-style-type: disc;color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;">Faq</li> -->
                    <li style="list-style-type: disc;color: #020a12; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;cursor: pointer;"
                        routerLink="contact-us">Contact Us</li>
                    <!-- <li style="list-style-type: disc;color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;">Wishlist</li> -->
                </ul>
            </div>
            <div class="footer-col avon-footer-cont" *ngIf="config.title == 'AvonSeals'">
                <ul>
                    <!-- <li style="list-style-type: disc;color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;">Compare</li> -->
                    <li style="list-style-type: disc;color: #020a12; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;cursor: pointer;"
                        (click)="showOrder()">Orders</li>
                    <!-- <li style="list-style-type: disc;color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;">Order Status</li> -->
                    <!-- <li style="list-style-type: disc;color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;">Deliveries</li> -->
                    <!-- <li style="list-style-type: disc;color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;">Cancellation</li> -->
                    <li style="list-style-type: disc;color: #020a12; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;cursor: pointer;"
                        (click)="openBranchLocation()">Store Location</li>
                </ul>
            </div>
            <div class="footer-col avon-footer-cont" *ngIf="config.title == 'AvonSeals'">
                <ul>
                    <!-- <li style="list-style-type: disc;color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;">Career</li> -->
                    <!-- <li style="list-style-type: disc;color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;">Help Center</li> -->
                    <li style="list-style-type: disc;color: #020a12; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;cursor: pointer;"
                        routerLink="terms-and-condition">Terms And Conditions</li>
                    <li style="list-style-type: disc;color: #020a12; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;cursor: pointer;"
                        routerLink="privacy-policy">Privacy Policy</li>
                    <li style="list-style-type: disc;color: #020a12; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;cursor: pointer;"
                        routerLink="cancellation-policy">Cancellation Policy</li>
                    <li style="list-style-type: disc;color: #020a12; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;cursor: pointer;"
                        routerLink="shipping-and-delivery">Shipping & Delivery</li>
                    <li style="list-style-type: disc;color: #020a12; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;cursor: pointer;"
                        routerLink="return-policy">Return & Exchanges</li>

                    <!-- <li style="list-style-type: disc;color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;">Advanced Search</li> -->
                    <!-- <li style="list-style-type: disc;color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;">Shipping Information</li> -->
                </ul>
            </div>






        </div>
    </div>
    <hr *ngIf="config.title == 'AvonSeals'" style="border: 1px solid black; margin-top: -20px;">
</footer>

<footer class="container-fluid footer" style="background-color: #020a12;" *ngIf="config.title === 'SpecialWires'">
    <div class="container">
        <div class="footer-links footer-row-one">

            <div class="footer-col special-col">

                <ul>
                    <!-- <li><p><strong style="color: #1330f1 !important;font-size:45px">SpecialWires</strong></p></li> -->
                    <img src="../../../../../assets/images/specialwires/logo-new.png"
                        style="object-fit: contain;width: 307px;margin-bottom: 25px;">
                    <br>
                    <li>
                        <p style="color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important; width: 300px;"
                            class="special-footertext">To supply to our clients “ The Right Material at the Right
                            Quality and in the Right Time.”</p>
                    </li><br>
                    <!-- <li><input placeholder="Enter Your Email" class="textareafull" name="inputValue"> </li><br> -->
                    <!-- <div class="buttonIn" style="display:flex;width:300px">
                        <input type="text" id="enter" placeholder="Enter Your Email">
                        <button >Subscribe</button>
                    </div> -->
                    <!-- <div class="col-md-5">
                        <div class="inq-news-fld-st" style="width: 400px;" >
                            <form [formGroup]="subscribeForm" novalidate >
                                <input type="text" placeholder="Enter your mail.." class="email" style="height: 38px;"
                                    formControlName="email" [class.is-invalid]="f.email.invalid && validateForm"
                                    required>
                                    <button class="special-subbtn" (click)="subscribe()">
                                        <p style="
                                        color: white;">SUBSCRIBE</p>
                                    </button>
                                <div class="invalid-feedback" *ngIf="f.email.errors">
                                    <div *ngIf="f.email.errors.required">
                                        Email Id is required.
                                    </div>  
                                    <div *ngIf="f.email.errors.pattern">
                                        Enter correct Email Id.
                                    </div>
                                    <div *ngIf="f.email.errors.maxlength">
                                        Email can't be more than 50 characters long.
                                    </div>
                                </div>
                                
                            </form>
                        </div>
                    </div> -->
                    <!-- <li><p style="color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;">We do not spam. We send offers instead.</p></li> -->
                    <!-- <li (click)="getBrochure()"><a >Brochure</a></li> -->
                </ul>
            </div>
            <div class="footer-col">
                <ul style="list-style-type:disc">
                    <li style="list-style-type: disc;cursor: pointer; color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;"
                        (click)="windowscroll()" routerLink="/">Home</li>
                    <li style="list-style-type: disc;cursor: pointer;color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;"
                        routerLink="about-us">About Us</li>
                    <!-- <li style="list-style-type: disc;cursor: pointer;color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;">Blog</li>
                <li style="list-style-type: disc;cursor: pointer;color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;">Faq</li> -->
                    <li style="list-style-type: disc;cursor: pointer;color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;"
                        routerLink="contact-us">Contact Us</li>
                    <!-- <li style="list-style-type: disc;cursor: pointer;color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;">Wishlist</li> -->
                </ul>
            </div>
            <div class="footer-col">
                <ul>
                    <li style="list-style-type: disc;color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;cursor: pointer;"
                        (click)="showOrder()">Orders</li>
                    <!-- <li style="list-style-type: disc;color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;">Order Status</li> -->
                    <!-- <li style="list-style-type: disc;color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;">Deliveries</li> -->
                    <!-- <li style="list-style-type: disc;color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;">Cancellation</li> -->
                    <li style="list-style-type: disc;color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;cursor: pointer;"
                        (click)="specialBranchLocation()">Store Location</li>
                </ul>
            </div>
            <div class="footer-col">
                <ul>
                    <li style="list-style-type: disc;color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;cursor: pointer;"
                        routerLink="terms-and-condition">Terms And Conditions</li>
                    <li style="list-style-type: disc;color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;cursor: pointer;"
                        routerLink="privacy-policy">Privacy Policy</li>
                    <li style="list-style-type: disc;color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;cursor: pointer;"
                        routerLink="cancellation-policy">Cancellation Policy</li>
                    <li style="list-style-type: disc;color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;cursor: pointer;"
                        routerLink="shipping-and-delivery">Shipping & Delivery</li>
                    <li style="list-style-type: disc;color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;cursor: pointer;"
                        routerLink="return-policy">Return & Exchanges</li>
                </ul>
            </div>

            <div class="footer-col special-footer-logo">

                <ul class="special-footer-logo1">
                    <!-- <li><p><strong style="color: #1330f1 !important;font-size:45px">SpecialWires</strong></p></li> -->
                    <img src="../../../../../assets/images/specialwires/spl wires trans.png"
                        style="object-fit: contain;width: 240px;margin-bottom: 25px;">
                </ul>
            </div>




        </div>
    </div>
</footer>
<footer class="container-fluid footer" *ngIf="config.title === 'Vistas'"
    style=" background-color: #89d7e4;   border-top: black 1px solid;">
    <div class="container">
        <div class="footer-links footer-row-one">

            <div class="footer-col vistas-footercol">

                <ul>
                    <!-- <li><p><strong style="color: #1330f1 !important;font-size:45px">SpecialWires</strong></p></li> -->
                    <img src="../../../../../assets/images/vistas/Final Logo.jpg"
                        style="object-fit: contain;width: 307px;margin-bottom: 25px;">
                    <br>
                    <li>
                        <p
                            style=" font-size: 15px;font-family: 'proxima-regular','sans-serif'!important; width: 300px;">
                            To supply to our clients “ The Right Material at the Best Quality and in the Right Time.”
                        </p>
                    </li><br>
                    <!-- <li><input placeholder="Enter Your Email" class="textareafull" name="inputValue"> </li><br> -->
                    <!-- <div class="buttonIn" style="display:flex;width:300px">
                        <input type="text" id="enter" placeholder="Enter Your Email">
                        <button >Subscribe</button>
                    </div> -->
                    <!-- <div class="col-md-5">
                        <div class="inq-news-fld-st" style="width: 400px;" >
                            <form [formGroup]="subscribeForm" novalidate >
                                <input type="text" placeholder="Enter your mail.." class="email" style="height: 38px;"
                                formControlName="email" [class.is-invalid]="f.email.invalid && validateForm"
                                required>
                                    <button class="special-subbtn" (click)="subscribe()">
                                        <p>SUBSCRIBE</p>
                                    </button>
                                <div class="invalid-feedback" *ngIf="f.email.errors">
                                    <div *ngIf="f.email.errors.required">
                                        Email Id is required.
                                    </div>  
                                    <div *ngIf="f.email.errors.pattern">
                                        Enter correct Email Id.
                                    </div>
                                    <div *ngIf="f.email.errors.maxlength">
                                        Email can't be more than 50 characters long.
                                    </div>
                                </div>
                                
                            </form>
                        </div>
                    </div> -->
                    <!-- <li><p style="color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;">We do not spam. We send offers instead.</p></li> -->
                    <!-- <li (click)="getBrochure()"><a >Brochure</a></li> -->
                </ul>
            </div>
            <div class="vistas-footmenu">
                <div class="vistas-footermenu">
                    <div class="footer-col">
                        <ul style="list-style-type:disc">
                            <li style="list-style-type: disc;cursor: pointer; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;"
                                (click)="windowscroll()" routerLink="/">Home</li>
                            <li style="list-style-type: disc;cursor: pointer; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;"
                                routerLink="about-us">About Us</li>
                            <!-- <li style="list-style-type: disc;cursor: pointer;color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;">Blog</li>
                <li style="list-style-type: disc;cursor: pointer;color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;">Faq</li> -->
                            <li style="list-style-type: disc;cursor: pointer; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;"
                                routerLink="contact-us">Contact Us</li>
                            <!-- <li style="list-style-type: disc;cursor: pointer;color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;">Wishlist</li> -->
                        </ul>
                    </div>
                    <div class="footer-col">
                        <ul>
                            <li style="list-style-type: disc; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;cursor: pointer;"
                                (click)="showOrder()">Orders</li>
                            <!-- <li style="list-style-type: disc;color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;">Order Status</li> -->
                            <!-- <li style="list-style-type: disc;color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;">Deliveries</li> -->
                            <!-- <li style="list-style-type: disc;color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;">Cancellation</li> -->
                            <li style="list-style-type: disc; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;cursor: pointer;"
                                (click)="specialBranchLocation()">Store Location</li>
                        </ul>
                    </div>
                    <div class="footer-col">
                        <ul>
                            <li style="list-style-type: disc; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;cursor: pointer;"
                                routerLink="terms-and-condition">Terms And Conditions</li>
                            <li style="list-style-type: disc;font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;cursor: pointer;"
                                routerLink="privacy-policy">Privacy Policy</li>
                            <li style="list-style-type: disc; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;cursor: pointer;"
                                routerLink="cancellation-policy">Cancellation Policy</li>
                            <li style="list-style-type: disc; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;cursor: pointer;"
                                routerLink="shipping-and-delivery">Shipping & Delivery</li>
                            <li style="list-style-type: disc; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;cursor: pointer;"
                                routerLink="return-policy">Return & Exchanges</li>
                        </ul>
                    </div>

                </div>

            </div>
        </div>
    </div>
</footer>
<footer>
    
    <div class="footer-row-two" *ngIf="config.title !== 'Deera'&& config.title !== 'Accel' && config.title !== 'AvonSeals' && config.title !== 'Babys Pride' && config.title !== 'Schift Cosmetics' && config.title !== 'BestGifts' && config.title !== 'SpecialWires' && config.title !== 'Vistas' && config.title !== 'BombayHardware' && config.title !== 'Aiema' && config.title !== 'Cle' && config.title !== 'LinenRaj' && config.title!=='Vimpro' && config.title!=='Beaubelle' && config.title!=='LushNGreen' && config.title !=='Taazah' && config.title !=='KatbookStore' && config.title !=='SriSenbaga' && config.title !== 'HOB' && config.title!=='PaulCaroline'  && config.title!=='Srimaware'">
        <div class="footer-social-icons" style="display: flex;">
            <ul class="social-icons">
                <li>
                    <a href="#">
                        <i class="fa fa-twitter"></i>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="fa fa-facebook"></i>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="fa fa-linkedin"></i>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="fa fa-google-plus" aria-hidden="true"></i>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="fa fa-pinterest" aria-hidden="true"></i>
                    </a>
                </li>

            </ul>

            <div *ngIf="config.title == 'BestGifts'">test</div>

        </div>
        <hr style="border: 1px solid white;">
    </div>
    <div class="footer-row-two schift-footer" style="background-color: #f5f5f5;" *ngIf="config.title == 'LushNGreen'">
        <div class="footer-col lush-footer-cont1">
            <img src="../../../../../assets/images/lushngreen/lushngreenlogo.png" class="lush-imglogo">
        </div>
        <div class="footer-col lush-footer-cont" *ngIf="config.title == 'LushNGreen' ">
            <h4 class="lush_footheading" style="color: #020a12; ">ABOUT US</h4>
            <ul style="list-style-type:disc; display: block;">
                <li class="lush_footer_text" routerLink="about-us">Our Story</li>
                <li class="lush_footer_text" routerLink="contact-us">Contact Us</li>
                <li class="lush_footer_text" (click)="locationLush()">Locate Store</li>
            </ul>
        </div>
        <div class="footer-col lush-footer-cont">
            <h4 class="lush_footheading" style="color: #020a12;">CUSTOMER CARE</h4>
            <ul style="display: block;">
                <li class="lush_footer_text" (click)="showOrder()">Track Orders</li>
                <li class="lush_footer_text" routerLink="terms-and-condition">Terms And Conditions</li>
                <li class="lush_footer_text" routerLink="privacy-policy">Privacy Policy</li>
                <li class="lush_footer_text" routerLink="cancellation-policy">Cancellation Policy</li>
                <!-- <li style=" color: #020a12; font-size: 15px;font-family: Trebuchet MS, sans-serif!important;cursor: pointer;" (click)="openBranchLocation()">Store Location</li> -->
                <!-- <li class="lush_footer_text" routerLink="terms-and-condition">Shipping Policy</li> -->
                <li class="lush_footer_text" routerLink="/FAQs">FAQs</li>
                <!-- <li class="lush_footer_text" routerLink="return-policy">Refund Policy</li> -->
            </ul>
        </div>
        <!-- <div class="footer-col lush-footer-cont">
            <h4 class="lush_footheading" style="color: #020a12;margin-bottom: 0px !important;">OFFERS & REWARDS</h4>
            <ul style="display: block;">
                <li class="lush_footer_text" (click)="lushPlant()">Flat 20% on all products</li>
            </ul>
        </div> -->
        <div class="footer-col lush-footer-cont">
            <h4 class="lush_footheading" style="color: #020a12;margin-bottom: 0px !important;">GET IN TOUCH</h4>
            <ul style="display: block;">
                <li class="lush_footer_text1">Call : +91 98765 43210</li>
                <li class="lush_footer_text1">Email : Support@lushngreens.com</li>
            </ul>
        </div>
        <div class="footer-col lush-footer-cont2">
            <h4 class="lush_footheading" style="color: #020a12;text-align: center;">SOCIAL MEDIA</h4>
            <ul class="social-icons3" style="margin-top: 15px;">
                <li style="cursor: pointer;margin: 5px 3px;">
                    <a (click)="windowOpen(facebookLink)">
                        <!-- <i style="border-radius: 40px;font-size: 12px !important;
                    padding: 6px;
                    color: white !important;
                    background-color: #c0a27e;" class="fa fa-facebook"></i> -->
                        <img src="../../../../../assets/images/lushngreen/facebook1.png" alt="" style="width: 30px;">
                    </a>
                </li>
                <li style="cursor: pointer;margin: 5px 3px;">
                    <a (click)="windowOpen(twitterLink)">
                        <!-- <i style="border-radius: 40px;font-size: 12px !important;
                    padding: 6px;
                    color: white !important;
                    background-color: #c0a27e;" class="fa fa-twitter"></i> -->
                        <img src="../../../../../assets/images/lushngreen/twitter1.png" alt="" style="width: 30px;">
                    </a>
                </li>
                <li style="cursor: pointer;margin: 5px 3px;">
                    <a (click)="windowOpen(whatsappLink)">
                        <!-- <i style="border-radius: 40px;font-size: 12px !important;
                    padding: 6px;
                    color: white !important;
                    background-color: #c0a27e;" class="fa fa-pinterest" aria-hidden="true"></i> -->
                        <img src="../../../../../assets/images/lushngreen/whatsapplogo1-removebg-preview.png" alt=""
                            style="width: 30px;">
                    </a>
                </li>
                <li style="cursor: pointer;margin: 5px 3px;">
                    <a (click)="windowOpen(instagramLink)">
                        <!-- <i style="border-radius: 40px;font-size: 12px !important;
                    padding: 6px;
                    color: white !important;
                    background-color: #c0a27e;" class="fa fa-instagram"></i> -->
                        <img src="../../../../../assets/images/lushngreen/instagram1.png" alt="" style="width: 30px;">
                    </a>
                </li>
                <!-- <li style="cursor: pointer;margin: 5px 3px;">
                <a  href="https://www.youtube.com/">
                    <img src="../../../../../assets/images/babyspride/youtube.png" alt="" style="width: 30px;">
                </a>
            </li> -->
            </ul>
        </div>
        <hr style="border: 1px solid #020a12;">
    </div>
    <div style="background-color: black;" *ngIf="config.title == 'KatbookStore'">
        <div class="footer-row-two Kat_footer">
            <div class="footer-col Kat-footer-cont1">
                <h3 style="color: white;font-size: 17px;">Install App</h3>
                <img src="../../../../../assets/images/kbook/googleplay.png" style="width: 51%;margin-top:10px;">
                <img src="../../../../../assets/images/kbook/applestore.png" style="width: 51%; margin-top:10px;">
            </div>
            <div class="footer-col Kat-footer-cont" *ngIf="config.title == 'KatbookStore' ">
                <h4 class="lush_footheading" style="color: rgb(168, 168, 168); font-size: 21px;">Information</h4>
                <ul style="list-style-type:disc; display: block;">
                    <li class="k_footer_text" routerLink="about-us" style="color: white;cursor: pointer;">About Us</li>
                    <li class="k_footer_text" style="color: white;cursor: pointer;">Features of KATBOOK</li>
                    <li class="k_footer_text" routerLink="about-us" style="color: white;cursor: pointer;">Reviews and
                        Ratings</li>
                    <li class="k_footer_text" routerLink="privacy-policy" style="color: white;cursor: pointer;">Privacy
                        Policy</li>
                    <li class="k_footer_text" routerLink="terms-and-condition" style="color: white;cursor: pointer;">
                        Terms and conditions</li>
                    <li class="k_footer_text" (click)="showOrder11()" style="color: white;cursor: pointer;">Orders and
                        Returns</li>
                    <li class="k_footer_text" routerLink="contact-us" style="color: white;cursor: pointer;">Contact Us
                    </li>
                </ul>
            </div>
            <div class="footer-col Kat-footer-cont">
                <h4 class="lush_footheading"
                    style="color: rgb(168, 168, 168);margin-bottom: 0px !important;font-size: 21px;">Contact Marketing
                    Manager</h4>
                <p style="color: rgb(168, 168, 168);">{{footer1.email}}</p>
                <ul class="social-icons2" style="justify-content: left; margin-top: 25px; ">
                    <li class="margin-baby-left" style="margin: 0px;">
                        <p style="color: #01a3ff;">Address :</p>
                        <p class="social-icon3"
                            style="color: white;font-family:Trebuchet MS, sans-serif;font-size: 14px;width: 75%;">
                            <!-- Baby's Pride Creations<br>
                            8/398-C, 3rd Floor<br>
                        Selvalakshminagar First Street<br>
                    Angeripalayam Main Road<br>
                Tirupur-641603 Tamilnadu -->
                            {{footer1.address1}},<br>{{footer1.city}},
                            {{footer1.state}} - {{footer1.zipcode}}
                        </p>
                        <p style="color: #01a3ff;margin-top: 20px;font-size: 14px;">Phones :</p>
                        <p style=" color: white ;font-family:Trebuchet MS, sans-serif;font-size: 14px;margin-top: 5px;">
                            +91 {{footer1.phone}}</p>
                        <p style=" color: white ;font-family:Trebuchet MS, sans-serif;font-size: 14px;margin-top: 5px;">
                            {{footer1.secphone}}</p>
                    </li>
                </ul>
            </div>
            <div class="footer-col Kat-footer-cont2">
                <h4 class="lush_footheading" style="color: rgb(168, 168, 168);font-size: 21px;">Location Map</h4>
                <img src="../../../../../assets/images/kbook/map.png" style="margin-top:10px;cursor: pointer;"
                    (click)="location()" class="kat_map">
            </div>



        </div>
        <hr style="border: 1px solid #f6f6f6;">
        <p style="text-align: center;font-size: 16px; font-weight: 500; color: white;">Copyright © 2021
            katbookstore.com.</p>
        <hr style="border: 1px solid #f6f6f6;margin-bottom: 0px;">
    </div>
    <div class="footer-row-two" style="background-color: #80808024;" *ngIf="config.title == 'AvonSeals'">
        <div class="footer-social-icons">

            <div class="footer-col avon-footer-cont" *ngIf="config.title == 'AvonSeals'">
                <ul class="social-icons2" *ngIf="config.title === 'AvonSeals'">
                    <li>
                        <p *ngIf="config.title == 'AvonSeals'" class="social-icon3" style="color: #020a12;"><i
                                style="color: #ff1f1f !important;" class="fa-map-marker fa"></i>
                            Avon Seals Private Limited, 11th street , G-2, Ambattur Industrial Estate, Chennai- 600 058,
                            Tamil Nadu, India</p>
                    </li>
                </ul>
            </div>
            <div class="footer-col avon-footer-cont" *ngIf="config.title == 'AvonSeals'">
                <ul class="social-icons2" style="height: 50px; display: flex; justify-content:flex-start;"
                    *ngIf="config.title === 'AvonSeals'">
                    <li>
                        <p *ngIf="config.title == 'AvonSeals'" style="font-size: 12px; color: #020a12 ;"><i
                                style="color: #ff1f1f !important;" class="fa fa-phone"></i>+91 44 42254995</p>

                    </li>
                </ul>
            </div>
            <div class="footer-col avon-footer-cont" *ngIf="config.title == 'AvonSeals'">
                <ul class="social-icons2" style="height: 50px; display: flex; justify-content:flex-start;"
                    *ngIf="config.title === 'AvonSeals'||  config.title == 'Schift Cosmetics' ">
                    <li>
                        <p *ngIf="config.title == 'AvonSeals'" style="font-size: 12px; color:#020a12 ;"><i
                                style="color: #ff1f1f !important;" class="fa fa-envelope"></i>sales@avonseals.com</p>
                    </li>
                </ul>
            </div>
            <div class="footer-col1 " *ngIf="config.title == 'AvonSeals'">
                <ul class="social-icons3" *ngIf="config.title === 'AvonSeals'">
                    <li style="margin: 5px 40px;">
                        <a href="#">
                            <i style="color: #ff1f1f !important;" class="fa fa-facebook"></i>
                        </a>
                    </li>
                    <li style="margin: 5px 40px;">
                        <a href="#">
                            <i style="color: #ff1f1f !important;" class="fa fa-twitter"></i>
                        </a>
                    </li>
                    <li style="margin: 5px 40px;">
                        <a href="#">
                            <i style="color: #ff1f1f !important;" class="fa fa-pinterest" aria-hidden="true"></i>
                        </a>
                    </li>
                    <li style="cursor: pointer;margin: 5px 40px;">
                        <a href="#">
                            <i style="color: #ff1f1f !important;" class="fa fa-instagram"></i>
                        </a>
                    </li>

                </ul>
            </div>
        </div>
        <hr style="border: 1px solid #020a12;">
    </div>
    <div class="footer-row-two schift-footer" style="background-color: #fdb88f;"
        *ngIf="config.title == 'Schift Cosmetics'">
        <div class="footer-col">
            <div class="just_center_log_foot">
                <img src="../../../../../assets/images/schift/1703598510345_-_Schift_Logo-removebg-preview-3.png"
                    class="schift-imglogo">
            </div>
        </div>
        <div class="footer-col schift-footer-cont" *ngIf="config.title == 'Schift Cosmetics' ">
            <h4 class="schift_footheading" style="color: #020a12; ">Useful Links</h4>
            <ul style="list-style-type:disc; display: block;">
                <li style=" color: #020a12; font-size: 13px;font-family: Trebuchet MS, sans-serif!important;cursor: pointer;"
                    (click)="windowscroll()" routerLink="/">Home</li>
                <li style=" color: #020a12; font-size: 13px;font-family: Trebuchet MS, sans-serif!important;cursor: pointer;"
                    routerLink="about-us">About Us</li>
                <li style=" color: #020a12; font-size: 13px;font-family: Trebuchet MS, sans-serif!important;cursor: pointer;"
                    routerLink="contact-us">Contact Us</li>
                <li style=" color: #020a12; font-size: 13px;font-family: Trebuchet MS, sans-serif!important;cursor: pointer;"
                    (click)="showOrder()">Orders</li>
                <!-- <li style=" color: #020a12; font-size: 15px;font-family: Trebuchet MS, sans-serif!important;cursor: pointer;" (click)="openBranchLocation()">Store Location</li> -->
                <li style=" color: #020a12; font-size: 13px;font-family: Trebuchet MS, sans-serif!important;cursor: pointer;"
                    routerLink="terms-and-condition">Terms And Conditions</li>
                <li style=" color: #020a12; font-size: 13px;font-family: Trebuchet MS, sans-serif!important;cursor: pointer;"
                    routerLink="privacy-policy">Privacy Policy</li>
            </ul>
        </div>
        <div class="footer-col schift-footer-cont">
            <h4 class="schift_footheading" style="color: #020a12;">Categories</h4>
            <ul style="display: block;">
                <li *ngFor="let online of onLineShopping_schift;" (click)="productsListTazTest1(online.link)"><a
                        class="custom-point1"
                        style="color: #020a12 !important; font-size: 13px !important;font-family: Trebuchet MS, sans-serif!important;cursor: pointer;">{{online.name}}</a>
                </li>
            </ul>
        </div>
        <div class="footer-col schift-footer-cont">
            <h4 class="schift_footheading" style="color: #020a12;margin-bottom: 0px !important;">Contact Us</h4>
            <ul class="social-icons2">
                <li>
                    <p class="social-icon3" style="color: #020a12;font-family:Trebuchet MS, sans-serif;font-size: 13px">
                        19, Velayudham Street, Nungambakkam, Chennai - 600034,</p>
                    <p style=" color: #020a12 ;font-family:Trebuchet MS, sans-serif;font-size: 13px">+91 9345062229,</p>
                    <p style=" color:#020a12 ;font-family:Trebuchet MS, sans-serif;font-size: 13px">
                        mohbgrowellchennai@gmail.com.</p>
                    <ul class="social-icons3" style="margin-top: 15px;">
                        <li style="cursor: pointer;margin: 5px 3px;">
                            <a href="https://www.facebook.com/">
                                <!-- <i style="border-radius: 40px;font-size: 12px !important;
                                    padding: 6px;
                                    color: white !important;
                                    background-color: #c0a27e;" class="fa fa-facebook"></i> -->
                                <img src="../../../../../assets/images/schift/Social-icon-1.png" alt=""
                                    style="width: 80%;">
                            </a>
                        </li>
                        <li style="cursor: pointer;margin: 5px 3px;">
                            <a href="https://twitter.com/i/flow/login">
                                <!-- <i style="border-radius: 40px;font-size: 12px !important;
                                    padding: 6px;
                                    color: white !important;
                                    background-color: #c0a27e;" class="fa fa-twitter"></i> -->
                                <img src="../../../../../assets/images/schift/Social-icon-2.png" alt=""
                                    style="width: 80%;">
                            </a>
                        </li>
                        <li style="cursor: pointer;margin: 5px 3px;">
                            <a href="https://in.pinterest.com/">
                                <!-- <i style="border-radius: 40px;font-size: 12px !important;
                                    padding: 6px;
                                    color: white !important;
                                    background-color: #c0a27e;" class="fa fa-pinterest" aria-hidden="true"></i> -->
                                <img src="../../../../../assets/images/schift/Social-icon-3.png" alt=""
                                    style="width: 80%;">
                            </a>
                        </li>
                        <li style="cursor: pointer;margin: 5px 3px;">
                            <a href="https://www.instagram.com/">
                                <!-- <i style="border-radius: 40px;font-size: 12px !important;
                                    padding: 6px;
                                    color: white !important;
                                    background-color: #c0a27e;" class="fa fa-instagram"></i> -->
                                <img src="../../../../../assets/images/schift/Social-icon-4.png" alt=""
                                    style="width: 80%;">
                            </a>
                        </li>
                        <li style="cursor: pointer;margin: 5px 3px;">
                            <a href="https://www.youtube.com/">
                                <!-- <i style="border-radius: 40px;font-size: 12px !important;
                                    padding: 6px;
                                    color: white !important;
                                    background-color: #c0a27e;" class="fa fa-instagram"></i> -->
                                <img src="../../../../../assets/images/schift/Social-icon-5.png" alt=""
                                    style="width: 80%;">
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>

        <div class="col-md-5 schift-f">
            <h4 class="sm-bold" style="color: #020a12;">Subscribe Now</h4>
            <div class="inq-news-fld-st ">
                <form [formGroup]="subscribeForm" novalidate class="responive_acc">
                    <input type="text" placeholder="Enter your mail.." class="schift-email" formControlName="email"
                        [class.is-invalid]="f.email.invalid && validateForm" required>

                    <div class="invalid-feedback" *ngIf="f.email.errors">
                        <div *ngIf="f.email.errors.required">
                            Email Id is required.
                        </div>
                        <div *ngIf="f.email.errors.pattern">
                            Enter correct Email Id.
                        </div>
                        <div *ngIf="f.email.errors.maxlength">
                            Email can't be more than 50 characters long.
                        </div>
                    </div>

                </form>

                <!-- <app-alerts></app-alerts> -->
            </div>
            <button class="vimpro-subbtn" (click)="subscribe()" *ngIf="config.title !== 'Schift Cosmetics'">
                <p style="
                color: white;">SUBSCRIBE</p>
            </button>
            <button class="vimpro-subbtn" (click)="subscribe()" *ngIf="config.title === 'Schift Cosmetics'">
                <p style="
                color: white;">SUBSCRIBE</p>
            </button>
        </div>
        <hr style="border: 1px solid #020a12;">
    </div>
    <div class="footer-row-two baby-footer" style="background-color: #bb086c;" *ngIf="config.title == 'Babys Pride'">
        <div class="footer-col baby-footer-cont baby-footer-cont1baby">
            <img src="../../../../../assets/images/babyspride/whitelogo-removebg-preview.png" class="baby-imglogo">
        </div>
        <div class="footer-col baby-footer-contmenu1">
            <h4 class="baby_footheading" style="color: white;margin-bottom: 0px !important;">CONTACT US</h4>
            <ul class="social-icons2">
                <li class="margin-baby-left">
                    <p class="social-icon3"
                        style="color: rgb(204, 203, 203);font-family:Trebuchet MS, sans-serif;font-size: 11px"><i
                            style="color: #80c340 !important;font-size: 11px;margin-right: 3px;"
                            class="fa-map-marker fa"></i>
                        <!-- Baby's Pride Creations<br>
                    8/398-C, 3rd Floor<br>
                Selvalakshminagar First Street<br>
            Angeripalayam Main Road<br>
        Tirupur-641603 Tamilnadu -->
                        {{footer1.address1}},<br>{{footer1.city}},
                        {{footer1.state}} - {{footer1.zipcode}}
                    </p>
                    <p
                        style=" color: rgb(204, 203, 203) ;font-family:Trebuchet MS, sans-serif;font-size: 11px;margin-top: 5px;">
                        <i style="color: #80c340 !important;font-size: 11px;margin-right: 3px;"
                            class="fa fa-phone"></i>+91 {{footer1.phone}},{{footer1.secphone}}</p>
                    <p
                        style=" color:rgb(204, 203, 203) ;font-family:Trebuchet MS, sans-serif;font-size: 11px;margin-top: 5px;">
                        <i style="color: #80c340 !important;font-size: 11px;margin-right: 3px;"
                            class="fa fa-envelope"></i>{{footer1.email}}</p>

                </li>
            </ul>
        </div>
        <div class="footer-col baby-footer-contmenu" *ngIf="config.title == 'Babys Pride' ">
            <h4 class="baby_footheading" style="color: white ">MENUS</h4>
            <ul style="list-style-type:disc; display: block;">
                <li style=" color:rgb(204, 203, 203) ;font-family:Trebuchet MS, sans-serif;font-size: 11px;margin-top: 5px;cursor: pointer;margin-bottom: 15px; margin-left: 3%;"
                    class="margin-baby-left" (ckick)="windowscroll()" routerLink="/">Home</li>
                <li style=" color:rgb(204, 203, 203) ;font-family:Trebuchet MS, sans-serif;font-size: 11px;margin-top: 5px;cursor: pointer;margin-bottom: 15px; margin-left: 3%;"
                    class="margin-baby-left" routerLink="about-us">About Us</li>
                <li style=" color:rgb(204, 203, 203) ;font-family:Trebuchet MS, sans-serif;font-size: 11px;margin-top: 5px;cursor: pointer;margin-bottom: 15px; margin-left: 3%;"
                    class="margin-baby-left" routerLink="contact-us">Contact Us</li>
                <li style=" color:rgb(204, 203, 203) ;font-family:Trebuchet MS, sans-serif;font-size: 11px;margin-top: 5px;cursor: pointer; margin-left: 3%;"
                    class="margin-baby-left" (click)="showOrder()">Orders</li>
                <!-- <li style=" color: #020a12; font-size: 15px;font-family: Trebuchet MS, sans-serif!important;cursor: pointer;" (click)="openBranchLocation()">Store Location</li> -->

            </ul>
        </div>
        <div class="footer-col baby-footer-cont">
            <h4 class="baby_footheading" style="color: white;">OUR POLICIES</h4>
            <ul style="display: block;">
                <li class="margin-baby-left"
                    style=" color:rgb(204, 203, 203) ;font-family:Trebuchet MS, sans-serif;font-size: 11px;margin-top: 5px;cursor: pointer;margin-bottom: 15px;"
                    routerLink="shipping-and-delivery">Shipping Policy</li>
                <li class="margin-baby-left"
                    style=" color:rgb(204, 203, 203) ;font-family:Trebuchet MS, sans-serif;font-size: 11px;margin-top: 5px;cursor: pointer;margin-bottom: 15px;"
                    routerLink="return-policy">Refund and return Policy</li>
                <li class="margin-baby-left"
                    style=" color:rgb(204, 203, 203) ;font-family:Trebuchet MS, sans-serif;font-size: 11px;margin-top: 5px;cursor: pointer;margin-bottom: 15px;"
                    routerLink="terms-and-condition">Terms And Conditions</li>
                <li class="margin-baby-left"
                    style=" color:rgb(204, 203, 203) ;font-family:Trebuchet MS, sans-serif;font-size: 11px;margin-top: 5px;cursor: pointer;"
                    routerLink="privacy-policy">Privacy Policy</li>
            </ul>
        </div>
        <div class="footer-col baby-footer-cont">
            <h4 class="baby_footheading" style="color: white;text-align: center;">SOCIAL MEDIA</h4>
            <ul class="social-icons3" style="margin-top: 15px;">
                <li style="cursor: pointer;margin: 5px 3px;">
                    <a (click)="windowOpen(facebookLink)">
                        <!-- <i style="border-radius: 40px;font-size: 12px !important;
                    padding: 6px;
                    color: white !important;
                    background-color: #c0a27e;" class="fa fa-facebook"></i> -->
                        <img src="../../../../../assets/images/babyspride/facebook.png" alt="" style="width: 30px;">
                    </a>
                </li>
                <li style="cursor: pointer;margin: 5px 3px;">
                    <a (click)="windowOpen(twitterLink)">
                        <!-- <i style="border-radius: 40px;font-size: 12px !important;
                    padding: 6px;
                    color: white !important;
                    background-color: #c0a27e;" class="fa fa-twitter"></i> -->
                        <img src="../../../../../assets/images/babyspride/twitter.png" alt="" style="width: 30px;">
                    </a>
                </li>
                <li style="cursor: pointer;margin: 5px 3px;">
                    <a (click)="windowOpen(whatsappLink)">
                        <!-- <i style="border-radius: 40px;font-size: 12px !important;
                    padding: 6px;
                    color: white !important;
                    background-color: #c0a27e;" class="fa fa-pinterest" aria-hidden="true"></i> -->
                        <img src="../../../../../assets/images/babyspride/whatsapplogo1-removebg-preview.png" alt=""
                            style="width: 30px;">
                    </a>
                </li>
                <li style="cursor: pointer;margin: 5px 3px;">
                    <a (click)="windowOpen(instagramLink)">
                        <!-- <i style="border-radius: 40px;font-size: 12px !important;
                    padding: 6px;
                    color: white !important;
                    background-color: #c0a27e;" class="fa fa-instagram"></i> -->
                        <img src="../../../../../assets/images/babyspride/instagram.png" alt="" style="width: 30px;">
                    </a>
                </li>
                <!-- <li style="cursor: pointer;margin: 5px 3px;">
                <a  href="https://www.youtube.com/">
                    <img src="../../../../../assets/images/babyspride/youtube.png" alt="" style="width: 30px;">
                </a>
            </li> -->
            </ul>
        </div>
    </div>
    <div class="footer-row-two taz-footer" style="background-color: #1b52a4;" *ngIf="config.title == 'Taazah'">
        <div class="footer-col taz-footer-cont taz-footer-cont1baby">
            <img src="../../../../../assets/images/taazah/logoBlue.jpg" alt="Logo Footer" title="Logo Footer" class="taz-imglogo" style="width: 65%;">
            <p class="Srimaware-footer-para" style="font-size:10px !important;text-align: justify;color: rgb(199 220 251);width: 80%;">Taazah is a vibrant supermarket offering fresh produce, quality groceries, and daily essentials. It aims to provide a convenient and enjoyable shopping experience with a wide range of products, including fruits, vegetables, dairy, and household items, ensuring customers find everything they need under one roof.
            </p>
        </div>

        <div class="footer-col taz-footer-contmenu" *ngIf="config.title == 'Taazah' ">
            <h4 class="baby_footheading" style="color: white;margin-bottom: 15px;">MENUS</h4>
            <ul style="list-style-type:disc; display: block;">
                <li style=" color:rgb(199 220 251) ;font-family:Trebuchet MS, sans-serif;font-size: 11px;margin-top: 5px;cursor: pointer;margin-bottom: 15px; margin-left: 3%;"
                    class="margin-baby-left" (click)="windowscroll()" routerLink="/">Home</li>
                <li style=" color:rgb(199 220 251) ;font-family:Trebuchet MS, sans-serif;font-size: 11px;margin-top: 5px;cursor: pointer;margin-bottom: 15px; margin-left: 3%;"
                    class="margin-baby-left" routerLink="about-us">About Us</li>
                <li style=" color:rgb(199 220 251) ;font-family:Trebuchet MS, sans-serif;font-size: 11px;margin-top: 5px;cursor: pointer;margin-bottom: 15px; margin-left: 3%;"
                    class="margin-baby-left" routerLink="contact-us">Contact Us</li>
                <li style=" color:rgb(199 220 251) ;font-family:Trebuchet MS, sans-serif;font-size: 11px;margin-top: 5px;cursor: pointer; margin-left: 3%;"
                    class="margin-baby-left" (click)="showOrder()">Orders</li>
                <!-- <li style=" color: #020a12; font-size: 15px;font-family: Trebuchet MS, sans-serif!important;cursor: pointer;" (click)="openBranchLocation()">Store Location</li> -->

            </ul>
        </div>
        <div class="footer-col taz-footer-cont1">
            <h4 class="baby_footheading" style="color: white;margin-bottom: 15px;">OUR POLICIES</h4>
            <ul style="display: block;">
                <li class="margin-baby-left"
                    style=" color:rgb(199 220 251);font-family:Trebuchet MS, sans-serif;font-size: 11px;margin-top: 5px;cursor: pointer;margin-bottom: 15px;margin-left: 3%;"
                    routerLink="shipping-and-delivery">Shipping Policy</li>
                <li class="margin-baby-left"
                    style=" color:rgb(199 220 251);font-family:Trebuchet MS, sans-serif;font-size: 11px;margin-top: 5px;cursor: pointer;margin-bottom: 15px;margin-left: 3%;"
                    routerLink="return-policy">Refund and return Policy</li>
                <li class="margin-baby-left"
                    style=" color:rgb(199 220 251);font-family:Trebuchet MS, sans-serif;font-size: 11px;margin-top: 5px;cursor: pointer;margin-bottom: 15px;margin-left: 3%;"
                    routerLink="terms-and-condition">Terms And Conditions</li>
                <li class="margin-baby-left"
                    style=" color:rgb(199 220 251);font-family:Trebuchet MS, sans-serif;font-size: 11px;margin-top: 5px;cursor: pointer;margin-left: 3%;"
                    routerLink="privacy-policy">Privacy Policy</li>
            </ul>
        </div>
        <div class="footer-col taz-footer-contmenu1">
            <h4 class="baby_footheading" style="color: white;margin-bottom: 15px;">CONTACT US</h4>
            <ul class="social-icons2">
                <li class="margin-Taz-left">
                    <div style="display: flex;">
                        <i style="color: white !important;font-size: 17px;margin-right: 3px;margin-top: 2px;"
                            class="fa-map-marker fa"></i>
                        <p class="social-icon3"
                            style="color: rgb(204, 203, 203);font-family:Trebuchet MS, sans-serif;font-size: 11px;margin-left: 3px;">
                            <!-- Baby's Pride Creations<br>
                    8/398-C, 3rd Floor<br>
                Selvalakshminagar First Street<br>
            Angeripalayam Main Road<br>
        Tirupur-641603 Tamilnadu -->
                            {{footer1.address1}},<br>{{footer1.city}},
                            {{footer1.state}} - {{footer1.zipcode}}
                        </p>
                    </div>
                    <div style="display: flex;margin-top: 10px;">
                        <img style="color: white !important;margin-right: 3px;width: 7%;margin-left: -2px;height: 16%;"
                            src="../../../../../assets/images/taazah/clock-removebg-preview.png" alt="hours" title="hours">
                        <p
                            style=" color: rgb(204, 203, 203) ;font-family:Trebuchet MS, sans-serif;font-size: 11px;margin-left: 3px;">
                            Hours : <br> Open - Close 8am - 10pm</p>
                    </div>
                    <div style="display: flex;margin-top: 10px;">
                        <i style="color: white !important;font-size: 15px;margin-right: 3px;" class="fa fa-phone"></i>
                        <p
                            style=" color: rgb(204, 203, 203) ;font-family:Trebuchet MS, sans-serif;font-size: 11px;margin-left: 3px;">
                            +91 {{footer1.phone}},{{footer1.secphone}}</p>
                    </div>
                </li>
            </ul>
        </div>
        <div class="footer-col taz-footer-cont1">
            <h4 class="baby_footheading taz_ppao" style="color: white;margin-bottom: 0px;">SOCIAL MEDIA</h4>
            <ul class="social-icons3" style="margin-top: 5px;">
                <li style="cursor: pointer;margin: 5px 3px;">
                    <!-- <a (click)="windowscroll()"> -->
                        <a href="https://www.facebook.com/">
                        <!-- <i style="border-radius: 40px;font-size: 12px !important;
                    padding: 6px;
                    color: white !important;
                    background-color: #c0a27e;" class="fa fa-facebook"></i> -->
                        <img src="../../../../../assets/images/taazah/facebook.png" alt="Facebook" title="Facebook" class="icon_size_taz">
                    </a>
                </li>
                <li style="cursor: pointer;margin: 5px 3px;">
                    <!-- <a (click)="windowscroll()"> -->
                        <a href="https://www.twitter.com/">
                        <!-- <i style="border-radius: 40px;font-size: 12px !important;
                    padding: 6px;
                    color: white !important;
                    background-color: #c0a27e;" class="fa fa-twitter"></i> -->
                        <img src="../../../../../assets/images/taazah/twitter.png" alt="Twitter" title="Twitter" class="icon_size_taz">
                    </a>
                </li>
                <!-- <li style="cursor: pointer;margin: 5px 3px;">
                <a (click)="windowscroll()">
                   
                    <img src="../../../../../assets/images/taazah/pinin.png" alt="" style="width: 23px;">
                </a>
            </li> -->
                <li style="cursor: pointer;margin: 5px 3px;">
                    <!-- <a (click)="windowscroll()"> -->
                        <a href="https://www.instagram.com/">
                        <!-- <i style="border-radius: 40px;font-size: 12px !important;
                    padding: 6px;
                    color: white !important;
                    background-color: #c0a27e;" class="fa fa-instagram"></i> -->
                        <img src="../../../../../assets/images/taazah/insta.png" alt="Instagram" title="Instagram" class="icon_size_taz">
                    </a>
                </li>
                <li style="cursor: pointer;margin: 5px 3px;">
                    <!-- <a (click)="windowscroll()"> -->
                        <a href="https://www.youtube.com/">
                        <!-- <i style="border-radius: 40px;font-size: 12px !important;
                    padding: 6px;
                    color: white !important;
                    background-color: #c0a27e;" class="fa fa-instagram"></i> -->
                        <img src="../../../../../assets/images/taazah/yout.png" alt="Youtube" title="Youtube" class="icon_size_taz">
                    </a>
                </li>
                <!-- <li style="cursor: pointer;margin: 5px 3px;">
                <a  href="https://www.youtube.com/">
                    <img src="../../../../../assets/images/babyspride/youtube.png" alt="" style="width: 30px;">
                </a>
            </li> -->
            </ul>
        </div>
    </div>
    <div class="footer-row-two desktop-only" style="background-color: #fefdeb;" *ngIf="config.title === 'Srimaware'">
        <div class="srimaware-enquiry" *ngIf = "config.title == 'Srimaware'">
            <p class="srimaware-enquiry-p" >For Sales Enquiry <b style="font-size:20px !important;">+91 63818 08872, 94433 22774</b></p>
        </div>
        <div class="taz-footer srimaware-footer">
        <div class="footer-col taz-footer-cont taz-footer-cont1baby" style="font-size:14px !important;">
            <img [src]="logoImage" class="taz-imglogo srimaware-logo" style="cursor: pointer;" (click)="windowscroll()" routerLink="/">
            <div style="font-size:14px !important;">
                <p class="Srimaware-footer-para" style="font-size:12px !important;text-align: justify;">Srimaware is a trusted plasticware manufacturer, offering durable, eco-friendly products for everyday use. With a focus on quality and sustainability, our innovative designs provide practical solutions for households and businesses, ensuring convenience, style, and long-lasting performance in every product.
                </p>
            </div>
        </div>
        <div class="footer-col srimaware-footer-contmenu" *ngIf="config.title === 'Srimaware'">
            <h4 class="baby_footheading" style="color:black;margin-bottom: 10px;font-size: 14px !important;letter-spacing: none;">Menus</h4>
            <ul style="list-style-type:disc; display: block;font-size:14px !important;">
                <li style="color:black;cursor: pointer;margin-left: 3%;font-size:12px !important;" class="margin-baby-left" (click)="windowscroll()" routerLink="/">Home</li>
                <li style="color:black;cursor: pointer;margin-left: 3%;font-size:12px !important;" class="margin-baby-left" routerLink="about-us">Company Profile</li>
                <li style="color:black;cursor: pointer;margin-left: 3%;font-size:12px !important;" class="margin-baby-left" (click)="getProducts()">Products</li>
                <!-- <li style="color:black;cursor: pointer;margin-left: 3%;font-size:12px !important;" class="margin-baby-left" (click)="getProducts()">Catalogue</li>
                <li style="color:black;cursor: pointer;margin-left: 3%;font-size:12px !important;" class="margin-baby-left" routerLink="about-us">Customise</li> -->
                <li style="color:black;cursor: pointer;margin-left: 3%;font-size:12px !important;" class="margin-baby-left" (click)="questions()">Feed Back</li>
                <li style="color:black;cursor: pointer;margin-left: 3%;font-size:12px !important;" class="margin-baby-left" routerLink="contact-us">Contact Us</li>
            </ul>
        </div>
    
        <div class="footer-col srimaware-footer-cont1">
            <h4 class="baby_footheading" style="color:black;margin-bottom: 10px;font-size: 14px !important;letter-spacing: none;">Our Policies</h4>
            <ul style="display: block;">
                <li class="margin-baby-left" style="color:black;cursor: pointer;margin-left: 3%;font-size:12px !important;" routerLink="shipping-and-delivery">Shipping Policies</li>
                <li class="margin-baby-left" style="color:black;cursor: pointer;margin-left: 3%;font-size:12px !important;" routerLink="return-policy">Refund and Return Policy</li>
                <li class="margin-baby-left" style="color:black;cursor: pointer;margin-left: 3%;font-size:12px !important;" routerLink="privacy-policy">Privacy Policy</li>
                <li class="margin-baby-left" style="color:black;cursor: pointer;margin-left: 3%;font-size:12px !important;" routerLink="terms-and-condition">Terms and Conditions</li>
            </ul>
        </div>
    
        <div class="footer-col srimaware-footer-contmenu1">
            <h4 class="baby_footheading" style="color:black;margin-bottom: 10px;font-size: 14px !important;letter-spacing: none;margin-left:4% !important;">SRIMAWARE</h4>
            <ul class="social-icons-srimaware">
                <li class="margin-baby-left">
                    <div class="contact-srimaware-div" style="margin-top:-3%;margin-left: 1%;">
                        <p class="contact-srimaware" style="font-size:12px !important;">
                            Special 'B' Type Shed, B1 First Main Road, 
                            PIPDIC Industrial Estate, 
                            Mettupalayam, 
                            Pondicherry - 605 009. INDIA
                        </p>
                            <br>
                            <div style="display: flex;"><b style="font-size: 12px;">P</b> <p style="font-size: 11px;margin-left: 3px;">0413 - 2966871</p></div>
                            <div style="display: flex;"> <b style="font-size: 12px;">M</b> <p style="font-size: 11px;margin-left: 3px;">+91 63818 08872, 94433 22774</p></div>
                            <div style="display: flex;"><b style="font-size: 12px;">E</b> <p style="font-size: 11px;margin-left: 3px;">srimotherplast@gmail.com</p></div>
                        
                    </div>
                </li>
            </ul>
        </div>
    
        <div class="footer-col srimaware-footer-contmenu1">
            <div >
                <h4 class="baby_footheading soc-heading-srimaware" style="color:black;margin-bottom: 10px;font-size: 15px !important;letter-spacing: none;">Social Media</h4>
                <ul class="social-icons3 soc-icon-srimaware" >
                    <li style="cursor: pointer;margin: 2px 3px !important;">
                        <a (click)="windowOpen(facebookLink)">
                            <img src="../../../../../assets/images/srima/Web1.jpg" alt="" style="width: 30px;">
                        </a>
                    </li>
                    <li style="cursor: pointer;margin: 2px 3px !important;">
                        <a (click)="windowOpen(twitterLink)">
                            <img src="../../../../../assets/images/srima/Web2.jpg" alt="" style="width: 30px;">
                        </a>
                    </li>
                    <li style="cursor: pointer;margin: 2px 3px !important;">
                        <a (click)="windowOpen(LinkedInLink)">
                            <img src="../../../../../assets/images/srima/Web3.jpg" alt="" style="width: 30px;">
                        </a>
                    </li>
                    <li style="cursor: pointer;margin: 2px 3px !important;">
                        <a (click)="windowOpen(YoutubeLink)">
                            <img src="../../../../../assets/images/srima/Web4.jpg" alt="" style="width: 30px;">
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        
    </div>
    </div>
    <div class="footer-row-two mobile-only" style="background-color: #fefdeb;" *ngIf="config.title === 'Srimaware'">
        <div class="srimaware-enquiry" *ngIf = "config.title == 'Srimaware'">
            <p class="srimaware-enquiry-p" >For Sales Enquiry <b style="font-size:18px !important;">+91 63818 08872, 94433 22774</b></p>
        </div>
        <div class="taz-footer srimaware-footer">
        <div class="footer-col taz-footer-cont taz-footer-cont1baby" style="font-size:14px !important;">
            <img [src]="logoImage" class="taz-imglogo srimaware-logo">
            <div style="font-size:14px !important;">
                <p class="Srimaware-footer-para" style="font-size:17px !important;text-align: justify;">Srimaware is a trusted plasticware manufacturer, offering durable, eco-friendly products for everyday use. With a focus on quality and sustainability, our innovative designs provide practical solutions for households and businesses, ensuring convenience, style, and long-lasting performance in every product.
                </p>
            </div>
        </div>
        <div class="footer-col srimaware-footer-contmenu" *ngIf="config.title === 'Srimaware'">
            <h4 class="baby_footheading" style="color:black;margin-bottom: 10px;font-size: 20px !important;letter-spacing: none;">Menus</h4>
            <ul style="list-style-type:disc; display: block;font-size:14px !important;">
                <li style="color:black;cursor: pointer;margin-left: 3%;font-size:17px !important;" class="margin-baby-left" (click)="windowscroll()" routerLink="/">Home</li>
                <li style="color:black;cursor: pointer;margin-left: 3%;font-size:17px !important;" class="margin-baby-left" routerLink="about-us">Company Profile</li>
                <li style="color:black;cursor: pointer;margin-left: 3%;font-size:17px !important;" class="margin-baby-left" (click)="getProducts()">Products</li>
                <!-- <li style="color:black;cursor: pointer;margin-left: 3%;font-size:17px !important;" class="margin-baby-left" (click)="getProducts()">Catalogue</li>
                <li style="color:black;cursor: pointer;margin-left: 3%;font-size:17px !important;" class="margin-baby-left" routerLink="about-us">Customise</li> -->
                <li style="color:black;cursor: pointer;margin-left: 3%;font-size:17px !important;" class="margin-baby-left" (click)="questions()">Feed Back</li>
                <li style="color:black;cursor: pointer;margin-left: 3%;font-size:17px !important;" class="margin-baby-left" routerLink="contact-us">Contact Us</li>
            </ul>
        </div>
    
        <div class="footer-col srimaware-footer-cont1">
            <h4 class="baby_footheading" style="color:black;margin-bottom: 10px;font-size: 20px !important;letter-spacing: none;">Our Policies</h4>
            <ul style="display: block;">
                <li class="margin-baby-left" style="color:black;cursor: pointer;margin-left: 3%;font-size:17px !important;" routerLink="shipping-and-delivery">Shipping Policies</li>
                <li class="margin-baby-left" style="color:black;cursor: pointer;margin-left: 3%;font-size:17px !important;" routerLink="return-policy">Refund and Return Policy</li>
                <li class="margin-baby-left" style="color:black;cursor: pointer;margin-left: 3%;font-size:17px !important;" routerLink="privacy-policy">Privacy Policy</li>
                <li class="margin-baby-left" style="color:black;cursor: pointer;margin-left: 3%;font-size:17px !important;" routerLink="terms-and-condition">Terms and Conditions</li>
            </ul>
        </div>
    
        <div class="footer-col srimaware-footer-contmenu1">
            <h4 class="baby_footheading" style="color:black;margin-bottom: 10px;font-size: 20px !important;letter-spacing: none;">SRIMAWARE</h4>
            <ul class="social-icons-srimaware">
                <li class="margin-baby-left">
                    <div class="contact-srimaware-div" style="display: flex;" style="margin-top:-3%;">
                        <p class="contact-srimaware" style="font-size:17px !important;">
                            Special 'B' Type Shed, B1 First Main Road, 
                            PIPDIC Industrial Estate, 
                            Mettupalayam, 
                            Pondicherry - 605 009. INDIA
                            <br><br>
                            <b>P</b> 0413 - 2966871<br>
                            <b>M</b> +91 63818 08872, 94433 22774<br>
                            <b>E</b> srimotherplast@gmail.com
                        </p>
                    </div>
                </li>
            </ul>
        </div>
    
        <div class="footer-col srimaware-footer-contmenu1">
            <div >
                <h4 class="baby_footheading soc-heading-srimaware" style="color:black;margin-bottom: 15px;font-size: 20px !important;letter-spacing: none;">Social Media</h4>
                <ul class="social-icons3 soc-icon-srimaware" style="margin-top: 18px;justify-content: start;">
                    <li style="cursor: pointer;margin: 2px 3px !important;">
                        <a (click)="windowOpen(facebookLink)">
                            <img src="../../../../../assets/images/srima/Web1.jpg" alt="" style="width: 50px;">
                        </a>
                    </li>
                    <li style="cursor: pointer;margin: 2px 3px !important;">
                        <a (click)="windowOpen(twitterLink)">
                            <img src="../../../../../assets/images/srima/Web2.jpg" alt="" style="width: 50px;">
                        </a>
                    </li>
                    <li style="cursor: pointer;margin: 2px 3px !important;">
                        <a (click)="windowOpen(LinkedInLink)">
                            <img src="../../../../../assets/images/srima/Web3.jpg" alt="" style="width: 50px;">
                        </a>
                    </li>
                    <li style="cursor: pointer;margin: 2px 3px !important;">
                        <a (click)="windowOpen(YoutubeLink)">
                            <img src="../../../../../assets/images/srima/Web4.jpg" alt="" style="width: 50px;">
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        
    </div>
    </div>
    <div class="srimaware-copyright" style="width:100%;background-color: #5e338f;height:50px;" *ngIf = "config.title == 'Srimaware'">
        <p class="srimaware-copyright-p" style="color:white;padding-bottom: 1%;margin-left: 10%;">© Copyright 2024. SRIMAWARE PLASTICS.</p>
    </div>  
    <div class="footer-row-two taz-footer" style="background-color: #f8ee12;" *ngIf="config.title == 'SriSenbaga'">
        <div class="footer-col taz-footer-cont taz-footer-cont1baby">
            <img src="../../../../../assets/images/shenbaga/shenbaga logo.png" class="taz-imglogo">
        </div>

        <div class="footer-col taz-footer-contmenu" *ngIf="config.title == 'SriSenbaga' ">
            <h4 class="baby_footheading" style="color: #25246a;margin-bottom: 15px;">MENUS</h4>
            <ul style="list-style-type:disc; display: block;">
                <li style=" color:black ;font-family:Trebuchet MS, sans-serif;font-size: 11px;margin-top: 5px;cursor: pointer;margin-bottom: 15px; margin-left: 3%;font-weight: 600;"
                    class="margin-baby-left" (click)="windowscroll()" routerLink="/">Home</li>
                <li style=" color:black ;font-family:Trebuchet MS, sans-serif;font-size: 11px;margin-top: 5px;cursor: pointer;margin-bottom: 15px; margin-left: 3%;font-weight: 600;"
                    class="margin-baby-left" (click)="getProducts()">Books</li>
                <li style=" color:black ;font-family:Trebuchet MS, sans-serif;font-size: 11px;margin-top: 5px;cursor: pointer;margin-bottom: 15px; margin-left: 3%;font-weight: 600;"
                    class="margin-baby-left" (click)="toggleSriBest()">Bestseller</li>
                <li style=" color:black ;font-family:Trebuchet MS, sans-serif;font-size: 11px;margin-top: 5px;cursor: pointer;margin-bottom: 15px; margin-left: 3%;font-weight: 600;"
                    class="margin-baby-left" (click)="getProducts()">Author</li>
                <li style=" color:black ;font-family:Trebuchet MS, sans-serif;font-size: 11px;margin-top: 5px;cursor: pointer;margin-bottom: 15px; margin-left: 3%;font-weight: 600;"
                    class="margin-baby-left" routerLink="about-us">About Us</li>
                <li style=" color:black ;font-family:Trebuchet MS, sans-serif;font-size: 11px;margin-top: 5px;cursor: pointer;margin-bottom: 15px; margin-left: 3%;font-weight: 600;"
                    class="margin-baby-left" routerLink="contact-us">Contact Us</li>
                <li style=" color:black;font-family:Trebuchet MS, sans-serif;font-size: 11px;margin-top: 5px;cursor: pointer; margin-left: 3%;font-weight: 600;"
                    class="margin-baby-left" (click)="showOrder()">Orders</li>
                <!-- <li style=" color: #020a12; font-size: 15px;font-family: Trebuchet MS, sans-serif!important;cursor: pointer;" (click)="openBranchLocation()">Store Location</li> -->

            </ul>
        </div>
        <div class="footer-col taz-footer-cont1">
            <h4 class="baby_footheading" style="color: #25246a;margin-bottom: 15px;">USEFUL LINKS</h4>
            <ul style="display: block;">
                <li class="margin-baby-left"
                    style=" color:black ;font-family:Trebuchet MS, sans-serif;font-size: 11px;margin-top: 5px;cursor: pointer;margin-bottom: 15px;font-weight: 600;"
                    routerLink="shipping-and-delivery">Shipping Policy</li>
                <li class="margin-baby-left"
                    style=" color:black ;font-family:Trebuchet MS, sans-serif;font-size: 11px;margin-top: 5px;cursor: pointer;margin-bottom: 15px;font-weight: 600;"
                    routerLink="return-policy">Refund and return Policy</li>
                <li class="margin-baby-left"
                    style=" color:black ;font-family:Trebuchet MS, sans-serif;font-size: 11px;margin-top: 5px;cursor: pointer;margin-bottom: 15px;font-weight: 600;"
                    routerLink="terms-and-condition">Terms And Conditions</li>
                <li class="margin-baby-left"
                    style=" color:black ;font-family:Trebuchet MS, sans-serif;font-size: 11px;margin-top: 5px;cursor: pointer;font-weight: 600;"
                    routerLink="privacy-policy">Privacy Policy</li>
            </ul>
        </div>
        <div class="footer-col taz-footer-contmenu1">
            <h4 class="baby_footheading" style="color: #25246a;margin-bottom: 15px;">CONTACT US</h4>
            <ul class="social-icons2">
                <li class="margin-baby-left">
                    <div style="display: flex;">
                        <i style="color: black !important;font-size: 17px;margin-right: 3px;margin-top: 2px;"
                            class="fa-map-marker fa"></i>
                        <p class="social-icon3"
                            style="color: black;font-family:Trebuchet MS, sans-serif;font-size: 11px;margin-left: 3px;font-weight: 600;">
                            <!-- Baby's Pride Creations<br>
                    8/398-C, 3rd Floor<br>
                Selvalakshminagar First Street<br>
            Angeripalayam Main Road<br>
        Tirupur-641603 Tamilnadu -->
                            {{footer1.address1}},<br>{{footer1.city}},
                            {{footer1.state}} - {{footer1.zipcode}}
                        </p>

                    </div>
                    <div style="display: flex;margin-top: 10px;">
                        <i style="color: black !important;font-size: 15px;margin-right: 3px;" class="fa fa-phone"></i>
                        <p
                            style=" color: black ;font-family:Trebuchet MS, sans-serif;font-size: 11px;margin-left: 3px;">
                            +91 {{footer1.phone}},<br>{{footer1.secphone}}</p>
                    </div>
                    <p
                        style=" color:black ;font-family:Trebuchet MS, sans-serif;font-size: 11px;margin-top: 5px;font-weight: 600;">
                        <i style="color: black !important;font-size: 11px;margin-right: 3px;"
                            class="fa fa-envelope"></i>{{footer1.email}}</p>
                </li>
            </ul>
            <div style="margin-top: 10px;">
                <ul class="social-icons3 Senbag_social" style="margin-top: 15px;">
                    <li style="cursor: pointer;margin: 5px 3px;">
                        <a (click)="windowOpen(facebookLink)">
                            <!-- <i style="border-radius: 40px;font-size: 12px !important;
                    padding: 6px;
                    color: white !important;
                    background-color: #c0a27e;" class="fa fa-facebook"></i> -->
                            <img src="../../../../../assets/images/shenbaga/facebook.png" alt="" style="width: 23px;">
                        </a>
                    </li>
                    <li style="cursor: pointer;margin: 5px 3px;">
                        <a (click)="windowOpen(twitterLink)">
                            <!-- <i style="border-radius: 40px;font-size: 12px !important;
                    padding: 6px;
                    color: white !important;
                    background-color: #c0a27e;" class="fa fa-twitter"></i> -->
                            <img src="../../../../../assets/images/shenbaga/twitter.png" alt="" style="width: 23px;">
                        </a>
                    </li>
                    <li style="cursor: pointer;margin: 5px 3px;">
                        <a (click)="windowOpen(whatsappLink)">
                            <!-- <i style="border-radius: 40px;font-size: 12px !important;
                    padding: 6px;
                    color: white !important;
                    background-color: #c0a27e;" class="fa fa-pinterest" aria-hidden="true"></i> -->
                            <img src="../../../../../assets/images/shenbaga/pintrest.png" alt="" style="width: 23px;">
                        </a>
                    </li>
                    <li style="cursor: pointer;margin: 5px 3px;">
                        <a (click)="windowOpen(instagramLink)">
                            <!-- <i style="border-radius: 40px;font-size: 12px !important;
                    padding: 6px;
                    color: white !important;
                    background-color: #c0a27e;" class="fa fa-instagram"></i> -->
                            <img src="../../../../../assets/images/shenbaga/insta.png" alt="" style="width: 23px;">
                        </a>
                    </li>
                    <li style="cursor: pointer;margin: 5px 3px;">
                        <a (click)="windowOpen(instagramLink)">
                            <!-- <i style="border-radius: 40px;font-size: 12px !important;
                    padding: 6px;
                    color: white !important;
                    background-color: #c0a27e;" class="fa fa-instagram"></i> -->
                            <img src="../../../../../assets/images/shenbaga/youtube.png" alt="" style="width: 23px;">
                        </a>
                    </li>
                    <!-- <li style="cursor: pointer;margin: 5px 3px;">
                <a  href="https://www.youtube.com/">
                    <img src="../../../../../assets/images/babyspride/youtube.png" alt="" style="width: 30px;">
                </a>
            </li> -->
                </ul>
            </div>
        </div>

    </div>
    <div class="footer-row-two taz-footer" style="background-color: #545454;"
        *ngIf="config.title == 'HOB' || config.title=='PaulCaroline'">
        <div class="footer-col taz-footer-cont taz-footer-cont1baby">
            <img [src]="logoImage" class="taz-imglogo">
        </div>

        <div class="footer-col taz-footer-contmenu" *ngIf="config.title == 'HOB' ">
            <h4 class="baby_footheading" style="color: white;margin-bottom: 15px;">MENUS</h4>
            <ul style="list-style-type:disc; display: block;">
                <li style=" color:white ;font-family:Trebuchet MS, sans-serif;font-size: 15px;margin-top: 5px;cursor: pointer;margin-bottom: 15px; margin-left: 3%;font-weight: 600;" class="margin-baby-left" (click)="windowscroll()" routerLink="/" >Home</li>
                <li style=" color:white ;font-family:Trebuchet MS, sans-serif;font-size: 15px;margin-top: 5px;cursor: pointer;margin-bottom: 15px; margin-left: 3%;font-weight: 600;" class="margin-baby-left desktop-only" *ngIf = "config.title == 'HOB'" (click)="toggleNew()" >New</li>
                <li style=" color:white ;font-family:Trebuchet MS, sans-serif;font-size: 15px;margin-top: 5px;cursor: pointer;margin-bottom: 15px; margin-left: 3%;font-weight: 600;" class="margin-baby-left mobile-only"  *ngIf = "config.title == 'HOB'" (click)="toggleNewMobile()" >New</li>
                <li style=" color:white ;font-family:Trebuchet MS, sans-serif;font-size: 15px;margin-top: 5px;cursor: pointer;margin-bottom: 15px; margin-left: 3%;font-weight: 600;" class="margin-baby-left" (click)="productsListHob('Women')" >Women</li>
                <li style=" color:white ;font-family:Trebuchet MS, sans-serif;font-size: 15px;margin-top: 5px;cursor: pointer;margin-bottom: 15px; margin-left: 3%;font-weight: 600;" class="margin-baby-left" (click)="productsListHob('Men')" >Men</li>
                <li style=" color:white ;font-family:Trebuchet MS, sans-serif;font-size: 15px;margin-top: 5px;cursor: pointer;margin-bottom: 15px; margin-left: 3%;font-weight: 600;" class="margin-baby-left" *ngIf = "config.title == 'HOB'" (click)="toggleSaleHOB()" >Sale</li>
                
                <!-- <li style=" color:white;font-family:Trebuchet MS, sans-serif;font-size: 15px;margin-top: 5px;cursor: pointer; margin-left: 3%;font-weight: 600;" class="margin-baby-left" (click)="showOrder()">Orders</li> -->
                <!-- <li style=" color: #020a12; font-size: 15px;font-family: Trebuchet MS, sans-serif!important;cursor: pointer;" (click)="openBranchLocation()">Store Location</li> -->

            </ul>
        </div>
        <div class="footer-col taz-footer-contmenu" *ngIf=" config.title=='PaulCaroline' ">
            <h4 class="baby_footheading" style="color: white;margin-bottom: 15px;">MENUS</h4>
            <ul style="list-style-type:disc; display: block;">
                <li style=" color:white ;font-family:Trebuchet MS, sans-serif;font-size: 15px;margin-top: 5px;cursor: pointer;margin-bottom: 15px; margin-left: 3%;font-weight: 600;"
                    class="margin-baby-left" (click)="windowscroll()" routerLink="/">Home</li>
                <li style=" color:white ;font-family:Trebuchet MS, sans-serif;font-size: 15px;margin-top: 5px;cursor: pointer;margin-bottom: 15px; margin-left: 3%;font-weight: 600;"
                    class="margin-baby-left" (click)="toggleSriBest()">Women</li>
                <li style=" color:white ;font-family:Trebuchet MS, sans-serif;font-size: 15px;margin-top: 5px;cursor: pointer;margin-bottom: 15px; margin-left: 3%;font-weight: 600;"
                    class="margin-baby-left" (click)="getProducts()">Men</li>
                <li style=" color:white ;font-family:Trebuchet MS, sans-serif;font-size: 15px;margin-top: 5px;cursor: pointer;margin-bottom: 15px; margin-left: 3%;font-weight: 600;"
                    class="margin-baby-left" routerLink="about-us">About Us</li>
                <li style=" color:white ;font-family:Trebuchet MS, sans-serif;font-size: 15px;margin-top: 5px;cursor: pointer;margin-bottom: 15px; margin-left: 3%;font-weight: 600;"
                    class="margin-baby-left" routerLink="contact-us">Contact Us</li>
                <!-- <li style=" color:white;font-family:Trebuchet MS, sans-serif;font-size: 15px;margin-top: 5px;cursor: pointer; margin-left: 3%;font-weight: 600;" class="margin-baby-left" (click)="showOrder()">Orders</li> -->
                <!-- <li style=" color: #020a12; font-size: 15px;font-family: Trebuchet MS, sans-serif!important;cursor: pointer;" (click)="openBranchLocation()">Store Location</li> -->

            </ul>
        </div>
        <div class="footer-col taz-footer-cont1">
            <h4 class="baby_footheading" style="color: white;margin-bottom: 15px;">USEFUL LINKS</h4>
            <ul style="display: block;">
                <li style=" color:white ;font-family:Trebuchet MS, sans-serif;font-size: 15px;margin-top: 5px;cursor: pointer;margin-bottom: 15px; margin-left: 3%;font-weight: 600;" class="margin-baby-left" routerLink="about-us">About Us</li>
                <li style=" color:white ;font-family:Trebuchet MS, sans-serif;font-size: 15px;margin-top: 5px;cursor: pointer;margin-bottom: 15px; margin-left: 3%;font-weight: 600;" class="margin-baby-left" routerLink="contact-us">Contact Us</li>
                <li class="margin-baby-left" style=" color:white ;font-family:Trebuchet MS, sans-serif;font-size: 15px;margin-top: 5px;cursor: pointer;margin-bottom: 15px;font-weight: 600;" routerLink="shipping-and-delivery">Shipping Policy</li>
                <li class="margin-baby-left" style=" color:white ;font-family:Trebuchet MS, sans-serif;font-size: 15px;margin-top: 5px;cursor: pointer;margin-bottom: 15px;font-weight: 600;" routerLink="return-policy">Refund and return Policy</li>
                <li class="margin-baby-left" style=" color:white ;font-family:Trebuchet MS, sans-serif;font-size: 15px;margin-top: 5px;cursor: pointer;margin-bottom: 15px;font-weight: 600;"  routerLink="terms-and-condition">Terms And Conditions</li>
                <li class="margin-baby-left" style=" color:white ;font-family:Trebuchet MS, sans-serif;font-size: 15px;margin-top: 5px;cursor: pointer;font-weight: 600;" routerLink="privacy-policy">Privacy Policy</li>
            </ul>
        </div>
        <div class="footer-col taz-footer-contmenu1">
            <h4 class="baby_footheading" style="color: white;margin-bottom: 15px;">CONTACT US</h4>
            <ul class="social-icons2">
                <li class="margin-baby-left">
                    <div style="display: flex;">
                        <i style="color: white !important;font-size: 17px;margin-right: 3px;margin-top: 2px;"
                            class="fa-map-marker fa"></i>
                        <p class="social-icon3"
                            style="color: white;font-family:Trebuchet MS, sans-serif;font-size: 15px;margin-left: 3px;font-weight: 600;">
                            <!-- Baby's Pride Creations<br>
                    8/398-C, 3rd Floor<br>
                Selvalakshminagar First Street<br>
            Angeripalayam Main Road<br>
        Tirupur-641603 Tamilnadu -->
                            {{footer1.address1}},<br>{{footer1.city}},
                            {{footer1.state}} - {{footer1.zipcode}}
                        </p>

                    </div>
                    <div style="display: flex;margin-top: 10px;">
                        <i style="color: white !important;font-size: 15px;margin-right: 3px;" class="fa fa-phone"></i>
                        <p
                            style=" color: white ;font-family:Trebuchet MS, sans-serif;font-size: 15px;margin-left: 3px;">
                            +91 {{footer1.phone}},<br>{{footer1.secphone}}</p>
                    </div>
                    <p
                        style=" color:white ;font-family:Trebuchet MS, sans-serif;font-size: 15px;margin-top: 5px;font-weight: 600;">
                        <i style="color: white !important;font-size: 11px;margin-right: 3px;"
                            class="fa fa-envelope"></i>{{footer1.email}}</p>
                </li>
            </ul>
            <div style="margin-top: 10px;">
                <ul class="social-icons3 Senbag_social" style="margin-top: 15px;">
                    <li style="cursor: pointer;margin: 5px 3px;">
                        <a (click)="windowOpen(facebookLink)">
                            <i style="border-radius: 40px;font-size: 12px !important;
                    padding: 6px 9px;
                    color: black !important;
                    background-color: white;" class="fa fa-facebook"></i>
                            <!-- <img src="../../../../../assets/images/shenbaga/facebook.png" alt="" style="width: 23px;"> -->
                        </a>
                    </li>
                    <li style="cursor: pointer;margin: 5px 3px;">
                        <a (click)="windowOpen(twitterLink)">
                            <i style="border-radius: 40px;font-size: 12px !important;
                    padding: 6px;
                    color: black !important;
                    background-color: white;" class="fa fa-twitter"></i>
                            <!-- <img src="../../../../../assets/images/shenbaga/twitter.png" alt="" style="width: 23px;"> -->
                        </a>
                    </li>
                    <li style="cursor: pointer;margin: 5px 3px;">
                        <a (click)="windowOpen(whatsappLink)">
                            <i style="border-radius: 40px;font-size: 12px !important;
                    padding: 6px;
                    color: black !important;
                    background-color: white;" class="fa fa-pinterest" aria-hidden="true"></i>
                            <!-- <img src="../../../../../assets/images/shenbaga/pintrest.png" alt="" style="width: 23px;"> -->
                        </a>
                    </li>
                    <li style="cursor: pointer;margin: 5px 3px;">
                        <a (click)="windowOpen(instagramLink)">
                            <i style="border-radius: 40px;font-size: 12px !important;
                    padding: 6px;
                    color: black !important;
                    background-color: white;" class="fa fa-instagram"></i>
                            <!-- <img src="../../../../../assets/images/shenbaga/insta.png" alt="" style="width: 23px;"> -->
                        </a>
                    </li>
                    <li style="cursor: pointer;margin: 5px 3px;">
                        <a (click)="windowOpen(instagramLink)">
                            <i style="border-radius: 40px;font-size: 12px !important;
                    padding: 6px;
                    color: black !important;
                    background-color: white;" class="fa fa-youtube-play"></i>
                            <!-- <img src="../../../../../assets/images/shenbaga/youtube.png" alt="" style="width: 23px;"> -->
                        </a>
                    </li>
                    <!-- <li style="cursor: pointer;margin: 5px 3px;">
                <a  href="https://www.youtube.com/">
                    <img src="../../../../../assets/images/babyspride/youtube.png" alt="" style="width: 30px;">
                </a>
            </li> -->
                </ul>
            </div>
        </div>

    </div>
    <div class="footer-row-two" style="background-color: #020a12;" *ngIf="config.title == 'SpecialWires'">
        <div class="footer-social-icons">

            <div class="footer-col">
                <ul class="social-icons1">
                    <li>
                        <p class="social-icon3"><i style="color: #0095da !important;" class="fa-map-marker fa"></i>
                            Unit 1 : 235 & Unit 2 : 219, 9th Street, Sidco Industrial Estate, Ambattur, Chennai - 600098
                        </p>
                    </li>
                </ul>
            </div>
            <div class="footer-col">
                <ul class="social-icons2" style="height: 50px; display: flex; justify-content:flex-start;width: 100%;">
                    <li>
                        <p style="font-size: 12px; color: aliceblue ;"><i style="color: #0095da !important;"
                                class="fa fa-phone"></i>+91 9790955409 / 044 42017070</p>
                    </li>
                </ul>
            </div>
            <div class="footer-col">
                <ul class="social-icons2" style="height: 50px; display: flex; justify-content:flex-start;">
                    <li>
                        <p style="font-size: 12px; color:aliceblue ;"><i style="color: #0095da !important;"
                                class="fa fa-envelope"></i>enquiry@specialwires.in</p>
                    </li>
                </ul>
            </div>
            <div class="footer-col1">
                <ul class="social-icons2" style="width: -webkit-fill-available;
        height: 50px;
        display: flex;
        justify-content: space-around;">
                    <li>
                        <a href="https://specialwires.ecbee.net/#/">
                            <i style="color: #0095da !important;" class="fa fa-facebook"></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://specialwires.ecbee.net/#/">
                            <i style="color: #0095da !important;" class="fa fa-twitter"></i>
                        </a>
                    </li>
                    <li style="cursor: pointer;">
                        <a href="https://specialwires.ecbee.net/#/">
                            <i style="color: #0095da !important;" class="fa fa-instagram"></i>
                        </a>
                    </li>

                </ul>
            </div>
        </div>
        <hr style="border: 1px solid white;">
    </div>
    <div *ngIf="config.title == 'Vistas'" class="footer-row-two"
        style="display: flex; justify-content: space-around;background-color: #89d7e4;border-top: black 1px solid;">

        <div class="footer-social-icons">
            <ul class="social-icons1 vistas-address">
                <li>
                    <p class="social-icon3" style="font-size: 14px;
                    color: black"><i style="color: #150e64 !important;" class="fa-map-marker fa"></i>
                        G1, New NO.1/182, Alankar Enclave, Alankar nagar 2nd street Sheik Maniyar, Vanagaram,Chennai,
                        Tamil Nadu, 600095</p>
                </li>
            </ul>


            <ul class="social-icons2 vistas-number">
                <li>
                    <p style="font-size: 14px;"><i style="color: #150e64 !important;" class="fa fa-phone"></i>+91
                        8015765631</p>
                </li>
            </ul>


            <ul class="social-icons2 vistas-number">
                <li>
                    <p style="font-size: 14px;"><i style="color: #150e64 !important;" class="fa fa-envelope"></i>
                        vistasexim06@gmail.com</p>
                </li>
            </ul>

        </div>
    </div>
</footer>
<footer *ngIf="config.title !== 'Accel'">

    <div class="footer-row-three" style="background-color:#bcb9b924;" *ngIf="config.title === 'LushNGreen'">
        <div class="s-icons" style="display: flex;margin-left: 20px;padding-top: 30px;">
            <p
                style="text-align: start;color: #020a12;font-size: 10px;font-family: Trebuchet MS, sans-serif !important;letter-spacing: 1px;">
                © 2024,<span style="font-family: cursive; font-size: 11px;">Lush n' Greens</span>. All rights reserved
            </p>
            <ul class="social-icons" style="width: 80%; display: flex; justify-content: flex-end;">
                <li>
                    <span style="margin-right: 5px;"><img src="../../../../../assets/images/avonseals/visa1.png"
                            alt=""></span>
                </li>

                <li>
                    <span style="margin-right: 5px;"> <img src="../../../../../assets/images/avonseals/master1.png"
                            alt=""></span>
                </li>

                <li>
                    <span style="margin-right: 5px;"> <img src="../../../../../assets/images/avonseals/amex1.png"
                            alt=""></span>
                </li>

                <li>
                    <span style="margin-right: 5px;"> <img src="../../../../../assets/images/avonseals/paypal1.png"
                            alt=""></span>
                </li>
                <li>
                    <span style="margin-right: 5px;"> <img src="../../../../../assets/images/avonseals/discover1.png"
                            alt=""></span>
                </li>



            </ul>

        </div>
        <hr style="margin-bottom: 0px !important;">
    </div>

    <div class="footer-row-three" style="background-color:#80808024;" *ngIf="config.title === 'AvonSeals'">
        <div class="s-icons" style=" height: 99px">
            <ul class="social-icons" style="display: flex; justify-content: flex-start;">
                <li>
                    <span style="margin-right: 5px;"><img src="../../../../../assets/images/avonseals/visa1.png"
                            alt=""></span>
                </li>

                <li>
                    <span style="margin-right: 5px;"> <img src="../../../../../assets/images/avonseals/master1.png"
                            alt=""></span>
                </li>

                <li>
                    <span style="margin-right: 5px;"> <img src="../../../../../assets/images/avonseals/amex1.png"
                            alt=""></span>
                </li>

                <li>
                    <span style="margin-right: 5px;"> <img src="../../../../../assets/images/avonseals/paypal1.png"
                            alt=""></span>
                </li>
                <li>
                    <span style="margin-right: 5px;"> <img src="../../../../../assets/images/avonseals/discover1.png"
                            alt=""></span>
                </li>
                <p style="font-size: 10px; text-align:end; color: #020a12;">© 2023,avonseals</p>


            </ul>
            <p class="pwrdby1"> Powered by</p>
            <img src="/assets/images/ecbeelogo.png" class="poweredbylogo1">
        </div>

    </div>
</footer>
<footer *ngIf="config.title === 'Schift Cosmetics'">
    <div class="footer-row-three desktop-only" style="background-color:#80808024;">
        <div class="s-icons" style=" height: 69px;width: 90%;">
            <div class="schift-pow">
                <p class="pwrdby1"> Powered by</p>
                <img src="/assets/images/ecbeelogo-removebg-preview.png" class="poweredbylogo1">
            </div>
        </div>

    </div>
    <div class="footer-row-three mobile-only" style="background-color:#80808024;">
        <div class="s-icons" style=" height: 50px;">
            <div class="schift-pow">
                <p class="pwrdby1"> Powered by</p>
                <img src="/assets/images/ecbeelogo-removebg-preview.png" class="poweredbylogo1">
            </div>
        </div>

    </div>

</footer>
<footer *ngIf="config.title === 'Babys Pride'">
    <div class="footer-row-three" style="background-color:#80808024;">
        <div class="s-icons cmp_logo_height" style="width: 90%;">
            <div class="schift-pow">
                <p class="pwrdby1"> Powered by</p>
                <img src="../../../../../assets/images/ecbeelogo-removebg-preview.png" class="poweredbylogo1">
            </div>
        </div>

    </div>


</footer>
<footer *ngIf="config.title === 'Srimaware'">
    <div class="footer-row-three" style="background-color:#80808024;">
        <div class="s-icons cmp_logo_height" style="width: 90%;">
            <div class="schift-pow">
                <p   class="pwrdby1">Powered by</p>
                <img  src="../../../../../assets/images/ecbeelogo-removebg-preview.png"  class="poweredbylogo1"> 
            </div>
        </div>
        
    </div>
</footer>
<footer *ngIf="config.title === 'Taazah'">
    <div class="footer-row-three desktop-only" style="background-color:#80808024;">
        <div class="s-icons cmp_logo_height" style="width: 90%;">
            <div class="schift-pow">
                <p class="pwrdby1"> Powered by</p>
                <img src="../../../../../assets/images/ecbeelogo-removebg-preview.png" alt="ECBEE" title="ECBEE" class="poweredbylogo1">
            </div>
        </div>

    </div>

    <div class="footer-row-three mobile-only marLushTop" style="background-color:#80808024;">
        <div class="s-icons cmp_logo_height" style="width: 90%;">
            <div class="schift-pow">
                <p class="pwrdbyLush"> Powered by</p>
                <img src="../../../../../assets/images/ecbeelogo-removebg-preview.png" alt="ECBEE" title="ECBEE" class="poweredbylogoLush">
            </div>
        </div>

    </div>
</footer>
<footer *ngIf="config.title === 'HOB'">
    <div class="footer-row-three" style="background-color:#80808024;">
        <div class="s-icons cmp_logo_height" style="width: 90%;">
            <div class="schift-pow">
                <p   class="pwrdby1" > Powered by</p>
                <img  src="../../../../../assets/images/ecbeelogo-removebg-preview.png"  class="poweredbylogo1"> 
            </div>
        </div>
        
    </div>


</footer>
<footer *ngIf="config.title === 'LushNGreen'">
    <div class="footer-row-three desktop-only" style="background-color:#80808024;">
        <div class="s-icons cmp_logo_height" style="width: 90%;">
            <div class="schift-pow">
                <p class="pwrdby1"> Powered by</p>
                <img src="../../../../../assets/images/ecbeelogo-removebg-preview.png" class="poweredbylogo1">
            </div>
        </div>

    </div>
    <div class="footer-row-three mobile-only marLushTop" style="background-color:#80808024;">
        <div class="s-icons cmp_logo_height" style="width: 90%;">
            <div class="schift-pow">
                <p class="pwrdbyLush"> Powered by</p>
                <img src="../../../../../assets/images/ecbeelogo-removebg-preview.png" class="poweredbylogoLush">
            </div>
        </div>

    </div>

</footer>
<footer *ngIf="config.title === 'KatbookStore'">
    <div class="footer-row-three" style="background-color:#80808024;">
        <div class="s-icons cmp_logo_height" style="width: 90%;">
            <div class="schift-pow">
                <p class="pwrdby1"> Powered by</p>
                <img src="../../../../../assets/images/ecbeelogo-removebg-preview.png" class="poweredbylogo1">
            </div>
        </div>

    </div>


</footer>
<footer *ngIf="config.title === 'Accel'">

    <div class="footer-row-two">
        <p class="pwrdby"> Powered by</p>
        <img src="/assets/images/ecbeelogo.png" class="poweredbylogo">
        <div class="s-icons">
            <ul class="social-icons">
                <li>
                    <a href="https://www.facebook.com/Acceledu/">
                        <i class="fa fa-facebook"></i>
                    </a>
                </li>
                <li style="cursor: pointer;">
                    <a href="https://www.instagram.com/acceledu/">
                        <i class="fa fa-instagram"></i>
                    </a>
                </li>
                <!-- <li>
                    <a href="#">
                        <i class="fa fa-twitter"></i>
                    </a>
                </li> -->
                <li>
                    <a href="https://www.linkedin.com/company/accelacademy/">
                        <i class="fa fa-linkedin"></i>
                    </a>
                </li>
                <!-- <li>
                    <a href="#">
                        <i class="fa fa-google-plus" aria-hidden="true"></i>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="fa fa-pinterest" aria-hidden="true"></i>
                    </a>
                </li> -->

            </ul>
        </div>
        <div *ngIf="copyRightText && copyRightText !== ''">
            <div class="copy">
                © {{copyRightText}}
            </div>
        </div>
    </div>
</footer>

<footer *ngIf="config.title !== 'AvonSeals' &&   config.title !== 'Schift Cosmetics' && config.title !== 'Vimpro' && config.title !== 'SpecialWires' && config.title !== 'Vistas' && config.title !== 'Cle' && config.title !== 'LinenRaj' && config.title !== 'Babys Pride' && config.title !== 'LushNGreen' && config.title !=='Taazah' && config.title !== 'Accel' && config.title !=='KatbookStore' && config.title !=='SriSenbaga' && config.title !== 'HOB' && config.title!=='PaulCaroline' && config.title!=='Srimaware' ">
    
    <div class="footer-row-two">
        <p class="pwrdby"> Powered by</p>
        <img src="/assets/images/ecbeelogo.png" class="poweredbylogo">
        <div class="s-icons">
            <ul class="social-icons">
                <li>
                    <a href="#">
                        <i class="fa fa-facebook"></i>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="fa fa-twitter"></i>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="fa fa-linkedin"></i>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="fa fa-google-plus" aria-hidden="true"></i>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="fa fa-pinterest" aria-hidden="true"></i>
                    </a>
                </li>

            </ul>
        </div>
        <div *ngIf="copyRightText && copyRightText !== ''">
            <div class="copy">
                © {{copyRightText}}
            </div>
        </div>
    </div>
</footer>
<footer *ngIf="config.title === 'Cle'" style="background-color: #f6f6f6;">

    <div class="footer-row-two">
        <p class="pwrdby"> Powered by</p>
        <img src="/assets/images/ecbeelogo.png" class="poweredbylogo">
        <div class="s-icons">
            <ul class="social-icons">
                <li>
                    <a href="#">
                        <i class="fa fa-facebook"></i>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="fa fa-twitter"></i>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="fa fa-linkedin"></i>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="fa fa-google-plus" aria-hidden="true"></i>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="fa fa-pinterest" aria-hidden="true"></i>
                    </a>
                </li>

            </ul>
        </div>
        <div *ngIf="copyRightText && copyRightText !== ''">
            <div class="copy">
                © {{copyRightText}}
            </div>
        </div>
    </div>
</footer>
<footer *ngIf="config.title === 'SpecialWires'">
    <div class="footer-row-three1">
        <p class="pwrdby2" style="color: white;"> Powered by</p>
        <img src="/assets/images/ecbeelogo.png" class="poweredbylogo1">
        <!-- <div *ngIf="copyRightText && copyRightText !== ''">
            <div class="copy">
                © {{copyRightText}}
            </div>
        </div> -->
    </div>
</footer>
<footer *ngIf="config.title === 'Vistas'">
    <div class="footer-row-three1">
        <p class="pwrdby3" style="color: white;"> Powered by</p>
        <img src="/assets/images/ecbeelogo.png" class="poweredbylogo2">
        <!-- <div *ngIf="copyRightText && copyRightText !== ''">
            <div class="copy">
                © {{copyRightText}}
            </div>
        </div> -->
    </div>
</footer>
<footer *ngIf="config.title === 'Vimpro'">
    <div class="footer-row-three1">
        <p class="pwrdby1"> Powered by</p>
        <img src="/assets/images/ecbeelogo.png" class="poweredbylogo1">
        <!-- <div *ngIf="copyRightText && copyRightText !== ''">
            <div class="copy">
                © {{copyRightText}}
            </div>
        </div> -->
    </div>
</footer>
<footer *ngIf="config.title === 'LinenRaj'">
    <div class="footer-row-three1">
        <p class="pwrdby1"> Powered by</p>
        <img src="/assets/images/ecbeelogo.png" class="poweredbylogo9">
        <!-- <div *ngIf="copyRightText && copyRightText !== ''">
            <div class="copy">
                © {{copyRightText}}
            </div>
        </div> -->
    </div>
</footer>